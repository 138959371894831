var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-12 text-justify bg-white q-px-md q-py-lg shadow-1",
          staticStyle: { "border-radius": "10px" }
        },
        [
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("POLÍTICAS DE INSPECCIÓN")])
          ]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _vm._v("Las inspecciones realizadas por "),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ". tendrán como referencia únicamente las normas técnicas o legales vigentes, en ningún caso se utilizará para la adopción o modificación\n      de procedimientos o instructivos criterios diferentes a los señalados en esas normas o las que las adicionen o modifiquen; nunca podrán usarse pautas de tipo subjetivo, que busquen limitar o reducir el acceso de los servicios de inspección\n      a un grupo determinado. Los parámetros de evaluación y medición de las inspecciones son los que señalen las normas técnicas o los reglamentos. Respecto de la inspección de vehículos y motocicletas eléctricas\n      se observara lo señalado en la resolución 1111 de 2013 del ministerio de medio ambiente. La imparcialidad, independencia e integridad son principios que rigen de manera absoluta las actuaciones de "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ".\n      como Organismo de Inspección y que son de obligatoria observancia por parte de su personal."
            )
          ]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              " Es responsable de los bienes y elementos de propiedad del usuario entregados para efectos de la inspección técnico mecánica, excepto en caso de fuerza mayor o caso fortuito o cuando\n      el daño se produzca como consecuencia de la exigencia técnica de la prueba, o por objetos de valor, dinero u otros bienes dejados en el vehículos y que no hayan sido declarado en el inventario."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [
              _vm._v("DECLARACIÓN DE IMPARCIALIDAD: AUTOGASES DE COLOMBIA S.A")
            ]),
            _vm._v(
              ". Estable que sus actividades de inspección de vehículos y motocicletas se ceñirían bajo la regla de imparcialidad e independencia y por tanto\n      proscribe cualquier presión que puede resultar de relaciones comerciales, financieras, convenios, actividades de sus funcionarios, relaciones o actividades de sus directivos y socios de la compañía. Las revisiones preventivas\n      y los avalúos no tendrán ninguna consideración o incidencia que afecte la imparcialidad en el evento en que un usuario tome dichos servicios y requiera realizar la revisión tecno mecánica y de emisiones contaminantes\n      oficial y/o reinspeccion."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ". como Organismo de Inspección ejecutará todas sus actividades de acuerdo a los principios de buena fe, lealtad y honorabilidad señalados en la Constitución Nacional, el Código\n      de Comercio y demás normas que las modifiquen o adicionen. En especial "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ". evitará siempre la práctica de conductas constitutivas de competencia desleal, o que busquen restringir\n      la competencia."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _vm._v("Todas las actuaciones de "),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ". como Organismo de Inspección son públicas, en tal sentido "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ". se obliga a prestar total cooperación a\n      las autoridades competentes que así lo requieran, siempre y cuando esto no contravenga nuestras obligaciones de confidencialidad de la información de los clientes."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("POLITICAS DE CONFIDENCIALIDAD")]),
            _vm._v(
              ": Excepto que la Ley o las autoridades de acreditación o las demás competentes lo requieran, "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              "., tratará de manera confidencial y no\n      revelará a terceros, sin previo consentimiento escrito del usuario, la información que repose en sus archivos o que fuere conocida y/o mantenida en posesión de sus funcionarios durante el curso de las inspecciones."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _vm._v(
              "En ejercicio de la actividad de inspección y por disposición legal, "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ", al momento de la recepción (orden de servicio) facturación y registro del vehículo o motocicleta,\n      debe recaudar información personal del usuario, correspondiente al nombre y apellido del propietario y/o tenedor del vehículo o motocicleta, número de la cédula de ciudadanía, dirección, teléfono, correo\n      electrónico, número y categoría de la licencia de conducción, seguro obligatorio y toda la información correspondiente al vehículo o motocicleta. La información personal y técnica antes señalada,\n      tiene como OBJETIVO, el registro y reporte ante el RUNT, SICOV- INDRA, ONAC, CORTOLIMA, SUPERPUESTOS, MINISTERIO DE TRANSPORTE y alimenta la base de datos de AUTOGASES DE COLOMBIA S.A, que la requiere para la expedición del certificado\n      RTME y EC y/o formato uniforme de resultados de la revisión técnico mecánica y de emisiones Contaminantes FUR; y realizar actividades de mercadeo, circunscritas a informar al usuario del vencimiento del certificado de revisión,\n      trámites pendientes ante el RUNT, o los organismos de tránsito y/o del seguro obligatorio y cualquier otra información relevante que afecte o modifique, las condiciones del certificado de revisión técnico mecánica\n      y de emisiones contaminantes u ofertas que realiza "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              ", como también para informar a los usuarios de los nuevos servicios y el vencimiento de estos. El usuario tendrá conocimiento del\n      reporte de la información suministrada por él y la acoplada durante el proceso de RTM y EC en el documento de Orden de Servicio toda la información registrada y reportada antes referenciadas están consignadas en el formulario\n      Uniformes de Resultados de la Revisión Técnico Mecánica y de Emisiones Contaminantes FUR. Además de lo anterior, autorizo a "
            ),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA SA")]),
            _vm._v(
              ". a usar la información que reposa en este documento\n      (orden de servicio) y el recibo del certificado de revisión técnico mecánica, el sistema de registro y cualquier otro mecanismo de recepción de información, para hacer uso de los datos allí consignados, con\n      el único fin de informarme sobre el vencimiento de la revisión técnico mecánica, así como de los cambios legales o reglamentarios que se produzcan al certificado o a la revisión y con fines comerciales en\n      materia de precios y promociones de conformidad a la ley 1581 de 2012 Autogases se ha comprometido en el documento Políticas de seguridad de la información a mantener la reserva que le señala la ley, a no divulgar información\n      del usuario si no en los casos que expresamente la ley determina y a darle el uso a la información que el usuario a autorizado."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("EXPERIENCIA DEL ORGANISMO DE INSPECCIÓN")]),
            _vm._v(": "),
            _c("strong", [_vm._v("AUTOGASES DE COLOMBIA S.A")]),
            _vm._v(
              "., se encuentra habilitada desde el 26 de julio de 2007 para realizar las revisiones tecno mecánicas y de emisiones contaminantes a\n      vehículos livianos y pesados mediante resolución 2998 del 26 de Julio de 2007 y para revisión de motocicletas desde el 2 de Diciembre de 2011, según resolución No. 5372 expedidas por el Ministerio de Transporte.\n      De igual manera se encuentra acreditada por la ONAC mediante certificado No. 09 OIN 043 expedida desde el 11 de septiembre de 2009 y actualmente vigente."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("CONDICIONES CONTRACTUALES")]),
            _vm._v(
              ": Autorizo a Autogases de Colombia S.A. en su calidad de Organismo de Inspección, para que lleve a cabo el servicio solicitado de acuerdo a las normas legales, reglamentarias y técnicas vigentes\n      sobre la materia, y según los reglamentos y procedimientos internos que el organismo de inspección ha elaborado, de conformidad a la resolución 3768 de 2013 expedida por el Ministerio de Transporte, las NTC 5375 y 53 85. Igualmente\n      declaro y acepto que me han sido suficientemente explicadas las condiciones de cada una de las pruebas que componen los servicios solicitados y el alcance de las mismas; en especial lo referido a las pruebas de emisiones de gases contaminantes reguladas,\n      por la resolución 910 de 2008, expedida por el Ministerio de Ambiente y Desarrollo Territorial y en las NTC 4194 - 4231,4983 y 5365."
            )
          ]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _vm._v(
              "Acepto que el vehículo durante el transcurso de las inspecciones sea conducido por el personal técnico de Autogases de Colombia S.A. Para la prueba de emisiones contaminantes en vehículo Diésel, acepto y declaro que en caso\n      de no tener instalado un sistema de gobernador en mi vehículo, me han sido suficientemente explicadas las condiciones de la prueba y autorizo expresamente con mi firma en este documento la realización de la prueba como se establece en\n      la NTC 4231 y asumo todas las consecuencias que se deriven de la misma."
            )
          ]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _vm._v(
              "De conformidad a la resolución 3768 de 2013 expedida por Ministerio de Transporte, cuando el vehículo o motocicleta ha sido reprobado, para la re-inspección que debe realizarse, previamente se hará una inspección sensorial\n      a fin de establecer que las condiciones iniciales referidas a la primera inspección no se han modificado. En casos de evidenciarse cambios en esta inspección sensorial, se procederá a realizar nuevamente la inspección técnico\n      mecánica completa, en cuyo caso el usuario deberá cancelar el valor correspondiente a la nueva inspección. De no evidenciarse cambios a las condiciones iniciales de inspección, se procederá a inspeccionar aquellos\n      ítems técnicos por los que réprobo inicialmente. La tarifa que se aplica para el servicio de la revisión Técnico mecánica es la determinada por la resolución 3318 de 2015 del Ministerio de Transporte.\n      El servicio se prestará en el horario de lunes a Viernes de 7:a.m. a 6: 00 p.m. y sábados de 7:00 a.m. a 5.00 p.m."
            )
          ]),
          _c("p", [_c("br")]),
          _c("p", { attrs: { dir: "ltr" } }, [
            _c("strong", [_vm._v("OBLIGACIONES DEL USUARIOS")]),
            _vm._v(
              ": Una vez se recepcione el vehículo el usuario debe ubicarse en la sala de espera dispuesta por el CDA. - "
            ),
            _c("strong", [
              _vm._v(
                "En el evento que el vehículo sometido a la inspección, repruebe algunos de los ítems de evaluación, el usuario cuenta con 15 días calendario contados desde la fecha y hora del pago de la revisión Tecnicomecanica para realizar los arreglos pertinentes y presentarlo nuevamente a revisión sin costo adicional"
              )
            ]),
            _vm._v(
              ".\n      Si los 15 días coinciden con un día dominical o feriado el usuario debe presentarse al CDA el día hábil inmediatamente anterior. El pago de servicio de revisión técnico mecánica no implica la expedición\n      del certificado. Este solamente se expide si el vehículo supera todas las pruebas objeto de revisión conforme los señalan los reglamentos técnicos. - Es deber del usuario abstenerse de dar dadivas, dinero u ofrecimientos\n      indebidos al personal del CDA. Una vez que el vehículo ha sido inspeccionado, no habrá devolución de dinero. "
            ),
            _c("strong", [
              _vm._v(
                "Pasados 15 días calendario desde la primera inspección si el vehículo no fue presentado para la reinspección, o no aprueba ésta en el lapso de los 15 días se genera un NUEVO COBRO por el valor de la tarifa señalado por el Ministerio de Transporte, y el proceso se reinicia como si fuera por primera vez."
              )
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
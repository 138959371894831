var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "p",
        {
          staticClass: "font-weight-bold q-my-lg",
          class: { "color-danger": _vm.itemsRequired }
        },
        [
          _c("span", [_vm._v("Inventario")]),
          _c("br"),
          _c(
            "small",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.itemsRequired,
                  expression: "itemsRequired"
                }
              ]
            },
            [_vm._v("Complete al menos un item del inventario")]
          )
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col col-lg-8 mx-auto" },
          [
            _vm._m(0),
            _vm._l(_vm.inventory.$model, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "row border border-t-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-4 col-md-5 col-lg-4 col-inventory-name font-weight-bold boder-botton"
                    },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "color-danger":
                              _vm.inventory.$each[index].quantity.$error ||
                              _vm.inventory.$each[index].evaluation.$error
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.name) +
                              "\n              "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col q-py-sm border-l col-checkout col-inventory text-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "q-mt-sm" },
                        [
                          _c("q-radio", {
                            staticClass: "q-mr-lg mx-auto",
                            attrs: { val: "B" },
                            model: {
                              value: item.evaluation,
                              callback: function($$v) {
                                _vm.$set(item, "evaluation", $$v)
                              },
                              expression: "item.evaluation"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col q-py-sm border-l col-checkout col-inventory"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "q-mt-sm" },
                        [
                          _c("q-radio", {
                            staticClass: "q-mr-lg mx-auto",
                            attrs: { val: "R" },
                            model: {
                              value: item.evaluation,
                              callback: function($$v) {
                                _vm.$set(item, "evaluation", $$v)
                              },
                              expression: "item.evaluation"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col q-py-sm border-l col-checkout col-inventory"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "q-mt-sm" },
                        [
                          _c("q-radio", {
                            staticClass: "q-mr-lg mx-auto",
                            attrs: { val: "M" },
                            model: {
                              value: item.evaluation,
                              callback: function($$v) {
                                _vm.$set(item, "evaluation", $$v)
                              },
                              expression: "item.evaluation"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col q-py-sm border-l col-checkout col-inventory"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "q-mt-sm" },
                        [
                          _c("q-radio", {
                            staticClass: "q-mr-lg mx-auto",
                            attrs: { val: "NA" },
                            model: {
                              value: item.evaluation,
                              callback: function($$v) {
                                _vm.$set(item, "evaluation", $$v)
                              },
                              expression: "item.evaluation"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col q-py-sm border-l col-count q-px-md" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col q-pt-xs" },
                          [
                            _c(
                              "q-field",
                              {
                                attrs: {
                                  error:
                                    _vm.inventory.$each[index].quantity.$error
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.evaluation == "NA"
                                          ? (item.quantity = 0)
                                          : ""
                                      )
                                    )
                                  ]
                                ),
                                _c("q-input", {
                                  staticClass: "bg-white q-py-sm q-my-md",
                                  attrs: { type: "number", min: "0" },
                                  model: {
                                    value: item.quantity,
                                    callback: function($$v) {
                                      _vm.$set(item, "quantity", $$v)
                                    },
                                    expression: "item.quantity"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.evaluation == "M" ||
                                  item.evaluation == "R",
                                expression:
                                  "item.evaluation == 'M' || item.evaluation == 'R'"
                              }
                            ],
                            staticClass: "col",
                            staticStyle: { "max-width": "50px" }
                          },
                          [
                            _c(
                              "q-field",
                              {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  error:
                                    _vm.inventory.$each[index].quantity.$error
                                }
                              },
                              [
                                _c(
                                  "q-btn",
                                  {
                                    staticClass: "q-px-sm bg-yellow",
                                    on: {
                                      click: function($event) {
                                        _vm.setObservations(
                                          _vm.inventory.$each[index].$model
                                        )
                                      }
                                    }
                                  },
                                  [_c("q-icon", { attrs: { name: "info" } })],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              )
            }),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col q-mt-md text-left" },
                [
                  _c(
                    "q-btn",
                    {
                      attrs: { round: "", color: "primary", size: "sm" },
                      on: {
                        click: function($event) {
                          _vm.showAddInventary = true
                        }
                      }
                    },
                    [_c("q-icon", { attrs: { name: "add" } })],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "q-ml-sm font-weight-bold cursor-pointer",
                      on: {
                        click: function($event) {
                          _vm.showAddInventary = true
                        }
                      }
                    },
                    [_vm._v(" Agregar Item")]
                  )
                ],
                1
              )
            ])
          ],
          2
        )
      ]),
      _c(
        "q-modal",
        {
          attrs: {
            "content-css": {
              minWidth: "30vw",
              padding: "25px",
              maxWidth: "100%"
            }
          },
          model: {
            value: _vm.showAddInventary,
            callback: function($$v) {
              _vm.showAddInventary = $$v
            },
            expression: "showAddInventary"
          }
        },
        [
          _c("h6", { staticClass: "mx-auto" }, [
            _vm._v("Nuevo elemento para el inventario")
          ]),
          _c(
            "div",
            { staticClass: "font-weight-bold" },
            [
              _c(
                "q-field",
                {
                  staticClass: "q-mt-lg",
                  attrs: { error: _vm.$v.addInventory.name.$invalid }
                },
                [
                  _c("q-input", {
                    attrs: { type: "text", placeholder: "Nombre" },
                    model: {
                      value: _vm.addInventory.name,
                      callback: function($$v) {
                        _vm.$set(_vm.addInventory, "name", $$v)
                      },
                      expression: "addInventory.name"
                    }
                  })
                ],
                1
              ),
              _c("q-tooltip", [
                !_vm.$v.addInventory.name.required
                  ? _c("p", [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v(" error_outline ")
                      ]),
                      _vm._v(" El campo es obligatorio.\n                ")
                    ])
                  : _vm._e(),
                !_vm.$v.addInventory.name.minLength
                  ? _c("p", [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v(" error_outline ")
                      ]),
                      _vm._v(
                        " El cambo debe contener minimo (" +
                          _vm._s(
                            _vm.$v.addInventory.name.$params.minLength.min
                          ) +
                          ") caracteres.\n                "
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("q-btn", {
            staticClass: "mt-2 q-mr-md",
            attrs: { color: "dark", label: "Cerrar" },
            on: {
              click: function($event) {
                _vm.showAddInventary = false
              }
            }
          }),
          _c("q-btn", {
            staticClass: "mt-2",
            attrs: { color: "primary", label: "Aceptar" },
            on: { click: _vm.addInventary }
          })
        ],
        1
      ),
      _c(
        "q-modal",
        {
          attrs: {
            "content-css": {
              minWidth: "30vw",
              padding: "25px",
              maxWidth: "100%"
            }
          },
          model: {
            value: _vm.observation.showModal,
            callback: function($$v) {
              _vm.$set(_vm.observation, "showModal", $$v)
            },
            expression: "observation.showModal"
          }
        },
        [
          _c("h6", { staticClass: "mx-auto" }, [
            _vm._v("Observaciones de " + _vm._s(_vm.observation.name))
          ]),
          _c(
            "div",
            { staticClass: "font-weight-bold" },
            [
              _c(
                "q-field",
                { staticClass: "q-mt-lg" },
                [
                  _c("q-input", {
                    staticClass: "bg-white",
                    attrs: { type: "textarea", "max-height": 10, rows: "3" },
                    model: {
                      value: _vm.observation.text,
                      callback: function($$v) {
                        _vm.$set(_vm.observation, "text", $$v)
                      },
                      expression: "observation.text"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("q-btn", {
            staticClass: "mt-2",
            attrs: { color: "primary", label: "Aceptar" },
            on: {
              click: function($event) {
                _vm.handlesimpleObservation()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row border" }, [
      _c("div", { staticClass: "col-12 col-sm-4 col-md-5 col-lg-4" }),
      _c(
        "div",
        { staticClass: "col q-py-md border-l font-weight-bold col-checkout" },
        [_vm._v("B")]
      ),
      _c(
        "div",
        { staticClass: "col q-py-md border-l font-weight-bold col-checkout" },
        [_vm._v("R")]
      ),
      _c(
        "div",
        { staticClass: "col q-py-md border-l font-weight-bold col-checkout" },
        [_vm._v("M")]
      ),
      _c(
        "div",
        { staticClass: "col q-py-md border-l font-weight-bold col-checkout" },
        [_vm._v("NA")]
      ),
      _c(
        "div",
        { staticClass: "col q-py-md border-l font-weight-bold col-count" },
        [_vm._v("Cant")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
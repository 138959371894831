var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "lHh LpR fFf" } },
    [_c("q-page-container", [_c("router-view"), _c("q-ajax-bar")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
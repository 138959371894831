var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-inner-loading",
    { attrs: { id: "innerLoadingMaster", visible: _vm.visible } },
    [
      _c(
        "div",
        { staticClass: "q-box-inner-loading" },
        [
          _c("q-spinner", { attrs: { size: _vm.size, color: "primary" } }),
          _vm.label
            ? _c("h6", { staticClass: "q-ma-none text-primary q-title" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./index2.vue?vue&type=template&id=a8f92fd2&scoped=true&"
import script from "./index2.vue?vue&type=script&lang=js&"
export * from "./index2.vue?vue&type=script&lang=js&"
import style0 from "./index2.vue?vue&type=style&index=0&id=a8f92fd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8f92fd2",
  null
  
)

component.options.__file = "index2.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$store.state.data.load_inner,
          expression: "!$store.state.data.load_inner"
        }
      ],
      staticClass: "create-vehicle"
    },
    [
      _c("div", { staticClass: "layout-padding" }, [
        _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col-12 q-px-md" }, [
            _c("span", { staticClass: "h4 q-mb-md border-bottom d-block" }, [
              _vm._v("\n          NUEVO VEHICULO\n        ")
            ])
          ])
        ]),
        _c("div", { staticClass: "row q-py-md border-bottom" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-6 col-lg-4 mx-auto q-px-md" },
            [
              _c(
                "q-field",
                [
                  _c("q-input", {
                    staticClass: "bg-white mx-auto",
                    attrs: {
                      type: "text",
                      placeholder: "Placa del Vehículo",
                      after: [
                        {
                          icon: "search",
                          handler: function handler() {
                            _vm.searchBoard()
                          }
                        }
                      ]
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchBoard($event)
                      }
                    },
                    model: {
                      value: _vm.board,
                      callback: function($$v) {
                        _vm.board = $$v
                      },
                      expression: "board"
                    }
                  }),
                  _c(
                    "q-tooltip",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$v.board.$error,
                          expression: "$v.board.$error"
                        }
                      ]
                    },
                    [
                      _vm.$v.board.$error
                        ? _c("p", { staticClass: "error-message mb-0" }, [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("error_outline")
                            ]),
                            _vm._v(" El campo es obligatorio.\n              ")
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm.vehicleExist
          ? _c("div", { staticClass: "row q-mt-lg" }, [
              _c(
                "div",
                { staticClass: "col-12 col-md-6 q-px-md" },
                [
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.board.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.board.$error
                          }
                        },
                        [_vm._v("Placa:")]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          readonly: "",
                          type: "text",
                          placeholder: "Placa"
                        },
                        model: {
                          value: _vm.attributes.board,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "board", $$v)
                          },
                          expression: "attributes.board"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.service_type.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.service_type.$error
                          }
                        },
                        [_vm._v("\n            Tipo de Servicio:\n          ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Tipo de Servicio",
                          options: _vm.$store.getters["data/GET_TYPES_SERVICES"]
                        },
                        model: {
                          value: _vm.attributes.service_type,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "service_type", $$v)
                          },
                          expression: "attributes.service_type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.type_vehicle.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.type_vehicle.$error
                          }
                        },
                        [_vm._v("\n            Tipo de Vehículo\n          ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Tipo de Vehículo",
                          options: _vm.$store.getters["data/GET_TYPES_VEHICLES"]
                        },
                        model: {
                          value: _vm.attributes.type_vehicle,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "type_vehicle", $$v)
                          },
                          expression: "attributes.type_vehicle"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.brand_id.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.brand_id.$error
                          }
                        },
                        [_vm._v("\n            Marca:\n          ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Marca",
                          options: _vm.$store.getters["data/GET_TYPES_BRANDS"]
                        },
                        model: {
                          value: _vm.attributes.brand_id,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "brand_id", $$v)
                          },
                          expression: "attributes.brand_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.line_id.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.line_id.$error
                          }
                        },
                        [_vm._v("\n            Línea:\n          ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Line",
                          options: _vm.$store.getters["data/GET_TYPES_LINES"]
                        },
                        model: {
                          value: _vm.attributes.line_id,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "line_id", $$v)
                          },
                          expression: "attributes.line_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.model.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.model.$error
                          }
                        },
                        [_vm._v("\n              Modelo:\n            ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Modelo",
                          options: _vm.$store.getters["data/GET_TYPES_MODELS"]
                        },
                        model: {
                          value: _vm.attributes.model,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "model", $$v)
                          },
                          expression: "attributes.model"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: { error: _vm.$v.attributes.transit_license.$error }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.transit_license.$error
                          }
                        },
                        [
                          _vm._v(
                            "\n            N° Licencia de Tránsito:\n          "
                          )
                        ]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          type: "text",
                          placeholder: "N° Licencia de Tránsito"
                        },
                        model: {
                          value: _vm.attributes.transit_license,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "transit_license", $$v)
                          },
                          expression: "attributes.transit_license"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: { error: _vm.$v.attributes.enrollment_date.$error }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.enrollment_date.$error
                          }
                        },
                        [
                          _vm._v(
                            "\n            Fecha de Matrícula:\n          "
                          )
                        ]
                      ),
                      _c("q-datetime", {
                        staticClass: "q-mb-lg",
                        attrs: {
                          type: "date",
                          placeholder: "Fecha de Matrícula",
                          format: "YYYY-MM-DD"
                        },
                        model: {
                          value: _vm.attributes.enrollment_date,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.attributes,
                              "enrollment_date",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "attributes.enrollment_date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-md-6 q-px-md" },
                [
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.color_id.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.color_id.$error
                          }
                        },
                        [_vm._v("\n            Color:\n          ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Color",
                          options: _vm.$store.getters["data/GET_TYPES_COLORS"]
                        },
                        model: {
                          value: _vm.attributes.color_id,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "color_id", $$v)
                          },
                          expression: "attributes.color_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.type_fuel.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.type_fuel.$error
                          }
                        },
                        [
                          _vm._v(
                            "\n            Tipo de combustible:\n          "
                          )
                        ]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Tipo de combustible",
                          options: _vm.$store.getters["data/GET_TYPES_FUELS"]
                        },
                        model: {
                          value: _vm.attributes.type_fuel,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "type_fuel", $$v)
                          },
                          expression: "attributes.type_fuel"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: { error: _vm.$v.attributes.vehicle_class.$error }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.vehicle_class.$error
                          }
                        },
                        [_vm._v("\n            Clase de vehícilo:\n          ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Clase de vehícilo",
                          options: _vm.$store.getters["data/GET_CLASS_VEHICLES"]
                        },
                        model: {
                          value: _vm.attributes.vehicle_class,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "vehicle_class", $$v)
                          },
                          expression: "attributes.vehicle_class"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.vin_number.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.vin_number.$error
                          }
                        },
                        [_vm._v("\n            N°Vin:\n          ")]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg",
                        attrs: { type: "text", placeholder: "N°Vin:" },
                        model: {
                          value: _vm.attributes.vin_number,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "vin_number", $$v)
                          },
                          expression: "attributes.vin_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: { error: _vm.$v.attributes.chasis_number.$error }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.chasis_number.$error
                          }
                        },
                        [_vm._v("\n            N° de Chasis:\n          ")]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg",
                        attrs: { type: "text", placeholder: "N° de Chasis" },
                        model: {
                          value: _vm.attributes.chasis_number,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "chasis_number", $$v)
                          },
                          expression: "attributes.chasis_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: { error: _vm.$v.attributes.engine_number.$error }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.engine_number.$error
                          }
                        },
                        [_vm._v("\n            N° de Motor:\n          ")]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg",
                        attrs: { type: "text", placeholder: "N° de Motor" },
                        model: {
                          value: _vm.attributes.engine_number,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "engine_number", $$v)
                          },
                          expression: "attributes.engine_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.displacement.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger":
                              _vm.$v.attributes.displacement.$error
                          }
                        },
                        [_vm._v("\n            Cilindraje:\n          ")]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg",
                        attrs: { type: "text", placeholder: "Cilindraje" },
                        model: {
                          value: _vm.attributes.displacement,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "displacement", $$v)
                          },
                          expression: "attributes.displacement"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.attributes.axes_number.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: {
                            "color-danger": _vm.$v.attributes.axes_number.$error
                          }
                        },
                        [_vm._v("\n            Numero de Ejes:\n          ")]
                      ),
                      _c("q-input", {
                        staticClass: "q-mb-lg",
                        attrs: { type: "text", placeholder: "Numero de Ejes" },
                        model: {
                          value: _vm.attributes.axes_number,
                          callback: function($$v) {
                            _vm.$set(_vm.attributes, "axes_number", $$v)
                          },
                          expression: "attributes.axes_number"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.attributes.type_vehicle == 1 ||
                  _vm.attributes.type_vehicle == 2
                    ? _c(
                        "q-field",
                        [
                          _c("q-checkbox", {
                            attrs: {
                              label: "Peso bruto del vehículo sobre 3500 ?",
                              "left-label": false
                            },
                            model: {
                              value: _vm.attributes.gross_weight_over_3500,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.attributes,
                                  "gross_weight_over_3500",
                                  $$v
                                )
                              },
                              expression: "attributes.gross_weight_over_3500"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-12 q-px-md" }, [
                      _c("h5", { staticClass: "border-bottom q-my-sm" }, [
                        _vm._v("\n              SOAT\n            ")
                      ])
                    ]),
                    _c(
                      "q-field",
                      {
                        staticClass: "col-6 col-sm-6 q-px-md",
                        attrs: {
                          error: _vm.$v.attributes.insurance_expedition.$error
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold d-inline-block",
                            class: {
                              "color-danger":
                                _vm.$v.attributes.insurance_expedition.$error
                            }
                          },
                          [
                            _vm._v(
                              "\n              Fecha de Expedición:\n            "
                            )
                          ]
                        ),
                        _c("q-datetime", {
                          staticClass: "q-mb-lg",
                          attrs: {
                            placeholder: "Fecha De Expedición",
                            type: "date",
                            format: "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.attributes.insurance_expedition,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.attributes,
                                "insurance_expedition",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "attributes.insurance_expedition"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "q-field",
                      {
                        staticClass: "col-6 col-sm-6 q-px-md",
                        attrs: {
                          error: _vm.$v.attributes.insurance_expiration.$error
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold d-inline-block",
                            class: {
                              "color-danger":
                                _vm.$v.attributes.insurance_expiration.$error
                            }
                          },
                          [
                            _vm._v(
                              "\n              Fecha Fin De Vigencia:\n            "
                            )
                          ]
                        ),
                        _c("q-datetime", {
                          staticClass: "q-mb-lg",
                          attrs: {
                            placeholder: "Fecha Fin de Vigencia",
                            type: "date",
                            format: "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.attributes.insurance_expiration,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.attributes,
                                "insurance_expiration",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "attributes.insurance_expiration"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-12 q-px-md" }, [
                      _c("h5", { staticClass: "border-bottom q-my-sm" }, [
                        _vm._v("\n              TECNICOMECÁNICA\n            ")
                      ])
                    ]),
                    _c(
                      "q-field",
                      { staticClass: "col-6 col-sm-6 q-px-md" },
                      [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold d-inline-block" },
                          [
                            _vm._v(
                              "\n              Fecha de Expedición:\n            "
                            )
                          ]
                        ),
                        _c("q-datetime", {
                          staticClass: "q-mb-lg",
                          attrs: {
                            placeholder: "Fecha de Expedición",
                            type: "date",
                            format: "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.attributes.tecnomecanica_expedition,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.attributes,
                                "tecnomecanica_expedition",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "attributes.tecnomecanica_expedition"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "q-field",
                      { staticClass: "col-6 col-sm-6 q-px-md" },
                      [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold d-inline-block" },
                          [
                            _vm._v(
                              "\n              Fecha Fin De Vigencia:\n            "
                            )
                          ]
                        ),
                        _c("q-datetime", {
                          staticClass: "q-mb-lg",
                          attrs: {
                            placeholder: "Fecha Fin De Vigencia",
                            type: "date",
                            format: "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.attributes.tecnomecanica_expiration,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.attributes,
                                "tecnomecanica_expiration",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "attributes.tecnomecanica_expiration"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "col-12 text-right q-py-lg" },
                [
                  _c("q-btn", {
                    staticClass: "q-px-lg btn-app",
                    attrs: { color: "black", size: "md", label: "Guardar" },
                    on: { click: _vm.submitData }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h5", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm._f("uppercase")(_vm.title)))
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 flex flex-center" },
          [
            _c(
              "q-scroll-area",
              { staticStyle: { width: "400px", height: "800px" } },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-center" },
                  _vm._l(_vm.orders, function(order, index) {
                    return _c(
                      "q-card",
                      {
                        key: index,
                        staticClass: "shadow-0",
                        staticStyle: { width: "95%" },
                        attrs: { inline: "" }
                      },
                      [
                        _c("q-card-title", [
                          _vm._v("\n            Matricula: "),
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm._f("uppercase")(order.vehicle.board))
                            )
                          ]),
                          _c("br"),
                          _c(
                            "div",
                            {
                              staticClass: "row items-center",
                              attrs: { slot: "right" },
                              slot: "right"
                            },
                            [
                              _c("q-icon", { attrs: { name: "style" } }),
                              _vm._v(
                                " " +
                                  _vm._s(order.inspection_status) +
                                  "\n            "
                              )
                            ],
                            1
                          )
                        ]),
                        _c("q-card-main", [
                          _c("p", [
                            _c("b", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("uppercase")(
                                      order.vehicle.user.fullname
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "text-faded" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6" }, [
                                _vm._v(
                                  "\n                  Tipo de Servicio:\n                "
                                )
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(order.vehicle.service_type_text)
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6" }, [
                                _vm._v(
                                  "\n                  Tipo de Vehiculo:\n                "
                                )
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(order.vehicle.type_vehicle_text)
                                  )
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _c("q-card-separator"),
                        _c(
                          "q-card-actions",
                          [
                            _c("q-btn", {
                              staticClass: "full-width",
                              attrs: {
                                to: {
                                  name: "update.inspection",
                                  params: {
                                    inspection: order.id
                                  }
                                },
                                rounded: "",
                                color: "primary",
                                label: "VER"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "q-inner-loading",
        { attrs: { visible: _vm.visible } },
        [_c("q-spinner-gears", { attrs: { size: "50px", color: "primary" } })],
        1
      ),
      _c("notificationComponent", {
        on: {
          newInspection: function($event) {
            _vm.handlenewInspection()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
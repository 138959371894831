export default {
	load_inner: true,
	types_documents: [],
	types_vehicles: [],
	types_inspections: [],
	types_inspections_statues: [],
	types_services: [],
	types_fuels: [],
	types_brands: [],
	types_colors: [],
	types_lines: [],
	types_models: [],
	test: [],
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "lHh LpR lFr" } },
    [
      _c(
        "q-layout-header",
        [
          _c(
            "div",
            { staticClass: "row bg-primary align-items-center q-py-sm" },
            [
              _c("div", { staticClass: "col-4 col-md-5 q-px-sm" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.leftDrawerOpen = !_vm.leftDrawerOpen
                      }
                    }
                  },
                  [
                    _c("img", {
                      style:
                        "" +
                        (_vm.$q.platform.is.mobile
                          ? "max-width:120px"
                          : "max-width:300px"),
                      attrs: { src: require("../assets/logo.png") }
                    })
                  ]
                )
              ]),
              _c("div", { staticClass: "col text-white" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.orden.timeEntry,
                          expression: "$store.state.orden.timeEntry"
                        }
                      ],
                      staticClass: "col header-col q-px-sm text-center"
                    },
                    [
                      _c("p", { staticClass: "font-weight-bold" }, [
                        _vm._v("Hora de ingreso:")
                      ]),
                      _c("p", { staticClass: "font-weight-bold q-mb-xs" }, [
                        _vm._v(_vm._s(_vm.$store.state.orden.timeEntry))
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.orden.dateEntry,
                          expression: "$store.state.orden.dateEntry"
                        }
                      ],
                      staticClass: "col header-col q-px-sm text-center"
                    },
                    [
                      _c("p", { staticClass: "font-weight-bold" }, [
                        _vm._v("Fecha de ingreso:")
                      ]),
                      _c("p", { staticClass: "font-weight-bold q-mb-xs" }, [
                        _vm._v(_vm._s(_vm.$store.state.orden.dateEntry))
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.orden.id,
                          expression: "$store.state.orden.id"
                        }
                      ],
                      staticClass:
                        "col-12 col-md-4 order-col q-px-sm text-center"
                    },
                    [
                      _c("p", { staticClass: "font-weight-bold mb-0" }, [
                        _vm._v("ORDEN DE SERVICIO")
                      ]),
                      _c("p", { staticClass: "font-weight-bold q-mb-xs h4" }, [
                        _vm._v(_vm._s(_vm.$store.state.orden.id))
                      ])
                    ]
                  )
                ])
              ])
            ]
          ),
          _c("q-progress", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.load,
                expression: "load"
              }
            ],
            attrs: { indeterminate: "", color: "warning" }
          })
        ],
        1
      ),
      _c(
        "q-layout-drawer",
        {
          attrs: { id: "menu_master", "content-class": "bg-grey-2" },
          model: {
            value: _vm.leftDrawerOpen,
            callback: function($$v) {
              _vm.leftDrawerOpen = $$v
            },
            expression: "leftDrawerOpen"
          }
        },
        [
          _c(
            "q-list",
            { attrs: { "no-border": "", link: "", "inset-delimiter": "" } },
            [
              _c("q-list-header", { staticClass: "text-center" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.leftDrawerOpen = !_vm.leftDrawerOpen
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "header-logo",
                      attrs: { src: require("../assets/logo.png") }
                    })
                  ]
                )
              ]),
              _c("widget-menu")
            ],
            1
          )
        ],
        1
      ),
      _c("q-page-container", [_c("router-view")], 1),
      _c(
        "q-inner-loading",
        { attrs: { visible: _vm.$store.state.data.load_inner } },
        [_c("q-spinner-gears", { attrs: { size: "50px", color: "primary" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "container-section-login" } },
    [
      _c(
        "q-page",
        {
          staticClass: "container-section",
          staticStyle: {
            "padding-top": "0px !important",
            "padding-bottom": "0px !important"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "row q-mx-md",
              staticStyle: { "min-height": "100vh" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-10 col-md-4 bg-primary text-center q-py-lg",
                  attrs: { id: "form-login-left" }
                },
                [
                  _c("img", {
                    staticClass: "q-my-lg",
                    attrs: {
                      src: require("../../assets/logo.svg"),
                      width: "50%"
                    }
                  }),
                  _c("hr", { staticClass: "col-12 q-my-lg" }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center q-mt-none q-my-lg q-pb-sm font-weight-bold"
                    },
                    [
                      _vm._v(
                        "\n\t\t       \t\tORDEN DE SERVICIO\n\t\t       \t"
                      )
                    ]
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: {
                        error: _vm.$v.form.username.$error,
                        "error-label": "This field is required"
                      }
                    },
                    [
                      _c("q-input", {
                        staticClass: "bg-white q-py-sm q-pl-sm",
                        attrs: {
                          name: "email",
                          autofocus: "",
                          autocomplete: "off",
                          type: "text",
                          before: [{ icon: "person" }],
                          placeholder: "Email"
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.authenticate()
                          }
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-field",
                    {
                      attrs: {
                        error: _vm.$v.form.password.$error,
                        "error-label": "This field is required"
                      }
                    },
                    [
                      _c("q-input", {
                        staticClass: "bg-white q-py-sm q-pl-sm",
                        attrs: {
                          type: "password",
                          name: "password",
                          before: [{ icon: "lock" }],
                          placeholder: "Password"
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.authenticate()
                          }
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center q-pt-lg bt-app" },
                    [
                      _c("q-btn", {
                        staticClass: "q-px-lg btn-app",
                        attrs: {
                          color: "dark",
                          name: "submit",
                          size: "md",
                          label: "Iniciar Sesión"
                        },
                        on: {
                          click: function($event) {
                            _vm.authenticate()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-block q-mt-md q-mb-lg text-right" },
        [
          _c("q-btn", {
            staticClass: "q-mx-md q-px-xs text-white",
            attrs: {
              color: "primary",
              size: "md",
              label: "Agregar fotografía",
              icon: "add"
            },
            on: {
              click: function($event) {
                _vm.openedUploader = true
              }
            }
          })
        ],
        1
      ),
      _c(
        "q-modal",
        {
          attrs: { "content-css": { minWidth: "80vw", padding: "25px" } },
          model: {
            value: _vm.openedUploader,
            callback: function($$v) {
              _vm.openedUploader = $$v
            },
            expression: "openedUploader"
          }
        },
        [
          _c("h4", { staticClass: "mx-auto" }, [_vm._v("Añadir imagen")]),
          _c(
            "div",
            [
              _c("q-uploader", {
                ref: "uploader",
                staticClass: "q-my-lg",
                attrs: {
                  url: _vm.url,
                  "auto-expand": true,
                  multiple: true,
                  extensions: ".jpg,.jpeg,.png",
                  "upload-factory": _vm.uploadFile
                },
                on: { "remove:cancel": _vm.removeUploader }
              }),
              _c("q-inner-loading", { attrs: { visible: _vm.visible } })
            ],
            1
          ),
          _vm.$q.platform.is.mobile
            ? _c(
                "div",
                [
                  _c("q-btn", {
                    attrs: { color: "black", icon: "photo_camera" },
                    on: {
                      click: function($event) {
                        _vm.captureImage()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", [
            _c("img", {
              attrs: {
                src: _vm.imageSrc,
                "placeholder-src": "statics/quasar-logo.png",
                alt: "Imagem: " + _vm.imageSrc,
                id: "photo"
              }
            })
          ]),
          _c("q-btn", {
            staticClass: "mt-2 q-mr-sm",
            attrs: { color: "dark", label: "Cerrar" },
            on: {
              click: function($event) {
                _vm.openedUploader = false
              }
            }
          }),
          _c("q-btn", {
            staticClass: "mt-2 mr-2",
            attrs: { color: "red", label: "Añadir", disabled: _vm.visible },
            on: { click: _vm.addUploader }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
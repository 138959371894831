var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$store.state.data.load_inner,
          expression: "!$store.state.data.load_inner"
        }
      ],
      staticClass: "create-vehicle"
    },
    [
      _c("div", { staticClass: "layout-padding" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 q-px-md" }, [
            _c("span", { staticClass: "h4 q-mb-md border-bottom d-block" }, [
              _vm._v(
                "\n              VEHICULO [" +
                  _vm._s(_vm.$route.params.board) +
                  "]\n            "
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-md-6 q-px-md" },
            [
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.service_type.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.service_type.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                Tipo de Servicio:\n              "
                      )
                    ]
                  ),
                  _c("q-select", {
                    staticClass: "q-mb-lg uppercase",
                    attrs: {
                      filter: "",
                      placeholder: "Tipo de Servicio",
                      options: _vm.$store.getters["data/GET_TYPES_SERVICES"]
                    },
                    model: {
                      value: _vm.attributes.service_type,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "service_type", $$v)
                      },
                      expression: "attributes.service_type"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.type_vehicle.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.type_vehicle.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                Tipo de Vehículo\n              "
                      )
                    ]
                  ),
                  _c("q-select", {
                    staticClass: "q-mb-lg uppercase",
                    attrs: {
                      filter: "",
                      placeholder: "Tipo de Vehículo",
                      options: _vm.$store.state.data.types_vehicles
                    },
                    model: {
                      value: _vm.attributes.type_vehicle,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "type_vehicle", $$v)
                      },
                      expression: "attributes.type_vehicle"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.vehicle_class.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.vehicle_class.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                Clase de Vehículo\n              "
                      )
                    ]
                  ),
                  _c("q-select", {
                    staticClass: "q-mb-lg uppercase",
                    attrs: {
                      filter: "",
                      placeholder: "Clase de Vehículo",
                      options: _vm.$store.state.data.class_vehicles
                    },
                    model: {
                      value: _vm.attributes.vehicle_class,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "vehicle_class", $$v)
                      },
                      expression: "attributes.vehicle_class"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-10" },
                  [
                    _c(
                      "q-field",
                      { attrs: { error: _vm.$v.attributes.brand_id.$error } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold d-inline-block",
                            class: {
                              "color-danger": _vm.$v.attributes.brand_id.$error
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Marca:\n                  "
                            )
                          ]
                        ),
                        _c("q-select", {
                          staticClass: "q-mb-lg uppercase",
                          attrs: {
                            filter: "",
                            placeholder: "Marca",
                            options: _vm.$store.state.data.types_brands
                          },
                          on: {
                            input: function($event) {
                              _vm.handleChangeBrand(_vm.attributes.brand_id)
                            }
                          },
                          model: {
                            value: _vm.attributes.brand_id,
                            callback: function($$v) {
                              _vm.$set(_vm.attributes, "brand_id", $$v)
                            },
                            expression: "attributes.brand_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [_c("brandComponent")],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-10" },
                  [
                    _c(
                      "q-field",
                      { attrs: { error: _vm.$v.attributes.line_id.$error } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold d-inline-block",
                            class: {
                              "color-danger": _vm.$v.attributes.line_id.$error
                            }
                          },
                          [_vm._v("Línea:")]
                        ),
                        _c("q-select", {
                          staticClass: "q-mb-lg uppercase",
                          attrs: {
                            filter: "",
                            placeholder: "Line",
                            options: _vm.SelectLines
                          },
                          model: {
                            value: _vm.attributes.line_id,
                            callback: function($$v) {
                              _vm.$set(_vm.attributes, "line_id", $$v)
                            },
                            expression: "attributes.line_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-2" }, [_c("lineComponent")], 1)
              ]),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.model.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: { "color-danger": _vm.$v.attributes.model.$error }
                    },
                    [_vm._v("Modelo:")]
                  ),
                  _c("q-select", {
                    staticClass: "q-mb-lg uppercase",
                    attrs: {
                      filter: "",
                      placeholder: "Modelo",
                      options: _vm.$store.state.data.types_models
                    },
                    model: {
                      value: _vm.attributes.model,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "model", $$v)
                      },
                      expression: "attributes.model"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.transit_license.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.transit_license.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                N° Licencia de Tránsito:\n              "
                      )
                    ]
                  ),
                  _c("q-input", {
                    staticClass: "q-mb-lg",
                    attrs: {
                      type: "text",
                      placeholder: "N° Licencia de Tránsito"
                    },
                    model: {
                      value: _vm.attributes.transit_license,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "transit_license", $$v)
                      },
                      expression: "attributes.transit_license"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.enrollment_date.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.enrollment_date.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                  Fecha de Matrícula:\n                "
                      )
                    ]
                  ),
                  _c("q-datetime", {
                    staticClass: "q-mb-lg",
                    attrs: {
                      type: "date",
                      placeholder: "Fecha de Vencimiento",
                      format: "YYYY-MM-DD"
                    },
                    model: {
                      value: _vm.attributes.enrollment_date,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.attributes,
                          "enrollment_date",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "attributes.enrollment_date"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-6 q-px-md" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c(
                      "q-field",
                      { attrs: { error: _vm.$v.attributes.color_id.$error } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold d-inline-block",
                            class: {
                              "color-danger": _vm.$v.attributes.color_id.$error
                            }
                          },
                          [_vm._v("Color:")]
                        ),
                        _c("q-select", {
                          staticClass: "q-mb-lg uppercase",
                          attrs: {
                            filter: "",
                            placeholder: "Color",
                            options: _vm.$store.state.data.types_colors
                          },
                          model: {
                            value: _vm.attributes.color_id,
                            callback: function($$v) {
                              _vm.$set(_vm.attributes, "color_id", $$v)
                            },
                            expression: "attributes.color_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-2 q-mt-md q-pl-lg" },
                  [_c("colorComponent")],
                  1
                )
              ]),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.type_fuel.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.type_fuel.$error
                      }
                    },
                    [_vm._v("Tipo de combustible:")]
                  ),
                  _c("q-select", {
                    staticClass: "q-mb-lg uppercase",
                    attrs: {
                      filter: "",
                      placeholder: "Tipo de combustible",
                      options: _vm.$store.state.data.types_fuels
                    },
                    model: {
                      value: _vm.attributes.type_fuel,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "type_fuel", $$v)
                      },
                      expression: "attributes.type_fuel"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.vin_number.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.vin_number.$error
                      }
                    },
                    [_vm._v("N°Vin:")]
                  ),
                  _c("q-input", {
                    staticClass: "q-mb-lg",
                    attrs: { type: "text", placeholder: "N°Vin:" },
                    model: {
                      value: _vm.attributes.vin_number,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "vin_number", $$v)
                      },
                      expression: "attributes.vin_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.chasis_number.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.chasis_number.$error
                      }
                    },
                    [_vm._v("N° de Chasis:")]
                  ),
                  _c("q-input", {
                    staticClass: "q-mb-lg",
                    attrs: { type: "text", placeholder: "N° de Chasis" },
                    model: {
                      value: _vm.attributes.chasis_number,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "chasis_number", $$v)
                      },
                      expression: "attributes.chasis_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.engine_number.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.engine_number.$error
                      }
                    },
                    [_vm._v("N° de Motor:")]
                  ),
                  _c("q-input", {
                    staticClass: "q-mb-lg",
                    attrs: { type: "text", placeholder: "N° de Motor" },
                    model: {
                      value: _vm.attributes.engine_number,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "engine_number", $$v)
                      },
                      expression: "attributes.engine_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.displacement.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.displacement.$error
                      }
                    },
                    [_vm._v("Cilindraje:")]
                  ),
                  _c("q-input", {
                    staticClass: "q-mb-lg",
                    attrs: { type: "text", placeholder: "Cilindraje" },
                    model: {
                      value: _vm.attributes.displacement,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "displacement", $$v)
                      },
                      expression: "attributes.displacement"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-field",
                { attrs: { error: _vm.$v.attributes.axes_number.$error } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold d-inline-block",
                      class: {
                        "color-danger": _vm.$v.attributes.axes_number.$error
                      }
                    },
                    [_vm._v("Numero de ejes:")]
                  ),
                  _c("q-input", {
                    staticClass: "q-mb-lg",
                    attrs: { type: "text", placeholder: "Numero de ejes" },
                    model: {
                      value: _vm.attributes.axes_number,
                      callback: function($$v) {
                        _vm.$set(_vm.attributes, "axes_number", $$v)
                      },
                      expression: "attributes.axes_number"
                    }
                  })
                ],
                1
              ),
              _vm.attributes.type_vehicle == 1 ||
              _vm.attributes.type_vehicle == 2
                ? _c(
                    "q-field",
                    [
                      _c("q-checkbox", {
                        attrs: {
                          label: "Peso bruto del vehículo sobre 3500 ?",
                          "left-label": false
                        },
                        model: {
                          value: _vm.attributes.gross_weight_over_3500,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.attributes,
                              "gross_weight_over_3500",
                              $$v
                            )
                          },
                          expression: "attributes.gross_weight_over_3500"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "col-12 q-px-md" }, [
                  _c("h5", { staticClass: "border-bottom q-my-sm" }, [
                    _vm._v("\n\t\t\t\t\t\t\t\tSOAT\n\t\t\t\t\t\t\t")
                  ])
                ]),
                _c(
                  "q-field",
                  {
                    staticClass: "col-6 col-sm-4 q-px-md",
                    attrs: {
                      error: _vm.$v.attributes.insurance_expedition.$error
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold d-inline-block",
                        class: {
                          "color-danger":
                            _vm.$v.attributes.insurance_expedition.$error
                        }
                      },
                      [
                        _vm._v(
                          "\n                Fecha de Expedición:\n              "
                        )
                      ]
                    ),
                    _c("q-datetime", {
                      staticClass: "q-mb-lg",
                      attrs: {
                        placeholder: "Fecha Fin De Vigencia",
                        type: "date",
                        format: "YYYY-MM-DD"
                      },
                      model: {
                        value: _vm.attributes.insurance_expedition,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.attributes,
                            "insurance_expedition",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "attributes.insurance_expedition"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "q-field",
                  {
                    staticClass: "col-6 col-sm-4 q-px-md",
                    attrs: {
                      error: _vm.$v.attributes.insurance_expiration.$error
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-bold d-inline-block",
                        class: {
                          "color-danger":
                            _vm.$v.attributes.insurance_expiration.$error
                        }
                      },
                      [
                        _vm._v(
                          "\n                Fecha Fin De Vigencia:\n              "
                        )
                      ]
                    ),
                    _c("q-datetime", {
                      staticClass: "q-mb-lg",
                      attrs: {
                        placeholder: "Fecha de Expedición",
                        type: "date",
                        format: "YYYY-MM-DD"
                      },
                      model: {
                        value: _vm.attributes.insurance_expiration,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.attributes,
                            "insurance_expiration",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "attributes.insurance_expiration"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "col-12 q-px-md" }, [
                  _c("h5", { staticClass: "border-bottom q-my-sm" }, [
                    _vm._v("TECNICOMECÁNICA")
                  ])
                ]),
                _c(
                  "q-field",
                  { staticClass: "col-6 col-sm-4 q-px-md" },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold d-inline-block" },
                      [
                        _vm._v(
                          "\n                Fecha de Expedición:\n              "
                        )
                      ]
                    ),
                    _c("q-datetime", {
                      staticClass: "q-mb-lg",
                      attrs: {
                        placeholder: "Fecha de Expedición",
                        type: "date",
                        format: "YYYY-MM-DD"
                      },
                      model: {
                        value: _vm.attributes.tecnomecanica_expedition,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.attributes,
                            "tecnomecanica_expedition",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "attributes.tecnomecanica_expedition"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "q-field",
                  { staticClass: "col-6 col-sm-4 q-px-md" },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold d-inline-block" },
                      [
                        _vm._v(
                          "\n                Fecha Fin De Vigencia:\n              "
                        )
                      ]
                    ),
                    _c("q-datetime", {
                      staticClass: "q-mb-lg",
                      attrs: {
                        placeholder: "Fecha Fin De Vigencia",
                        type: "date",
                        format: "YYYY-MM-DD"
                      },
                      model: {
                        value: _vm.attributes.tecnomecanica_expiration,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.attributes,
                            "tecnomecanica_expiration",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "attributes.tecnomecanica_expiration"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "col-12 text-right q-py-lg" },
            [
              _c("q-btn", {
                staticClass: "q-px-lg q-mr-sm btn-app",
                attrs: { color: "red", size: "md", label: "Atras" },
                on: { click: _vm.back }
              }),
              _c("q-btn", {
                staticClass: "q-px-lg btn-app",
                attrs: { color: "black", size: "md", label: "Guardar" },
                on: { click: _vm.submitData }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
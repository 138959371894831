<template>
  <div class="row">
  <div class="col-12 text-justify bg-white q-px-md q-py-lg shadow-1" style="border-radius: 10px">
    <p dir="ltr"><strong>POL&Iacute;TICAS DE INSPECCI&Oacute;N</strong></p>
    <p dir="ltr">Las inspecciones realizadas por <strong>AUTOGASES DE COLOMBIA S.A</strong>. tendr&aacute;n como referencia &uacute;nicamente las normas t&eacute;cnicas o legales vigentes, en ning&uacute;n caso se utilizar&aacute; para la adopci&oacute;n o modificaci&oacute;n
      de procedimientos o instructivos criterios diferentes a los se&ntilde;alados en esas normas o las que las adicionen o modifiquen; nunca podr&aacute;n usarse pautas de tipo subjetivo, que busquen limitar o reducir el acceso de los servicios de inspecci&oacute;n
      a un grupo determinado. Los par&aacute;metros de evaluaci&oacute;n y medici&oacute;n de las inspecciones son los que se&ntilde;alen las normas t&eacute;cnicas o los reglamentos. Respecto de la inspecci&oacute;n de veh&iacute;culos y motocicletas el&eacute;ctricas
      se observara lo se&ntilde;alado en la resoluci&oacute;n 1111 de 2013 del ministerio de medio ambiente. La imparcialidad, independencia e integridad son principios que rigen de manera absoluta las actuaciones de <strong>AUTOGASES DE COLOMBIA S.A</strong>.
      como Organismo de Inspecci&oacute;n y que son de obligatoria observancia por parte de su personal.</p>
    <p dir="ltr"><strong>AUTOGASES DE COLOMBIA S.A</strong>&nbsp;Es responsable de los bienes y elementos de propiedad del usuario entregados para efectos de la inspecci&oacute;n t&eacute;cnico mec&aacute;nica, excepto en caso de fuerza mayor o caso fortuito o cuando
      el da&ntilde;o se produzca como consecuencia de la exigencia t&eacute;cnica de la prueba, o por objetos de valor, dinero u otros bienes dejados en el veh&iacute;culos y que no hayan sido declarado en el inventario.</p>
    <p><br></p>
    <p dir="ltr"><strong>DECLARACI&Oacute;N DE IMPARCIALIDAD: AUTOGASES DE COLOMBIA S.A</strong>.&nbsp;Estable que sus actividades de inspecci&oacute;n de veh&iacute;culos y motocicletas se ce&ntilde;ir&iacute;an bajo la regla de imparcialidad e independencia y por tanto
      proscribe cualquier presi&oacute;n que puede resultar de relaciones comerciales, financieras, convenios, actividades de sus funcionarios, relaciones o actividades de sus directivos y socios de la compa&ntilde;&iacute;a. Las revisiones preventivas
      y los aval&uacute;os no tendr&aacute;n ninguna consideraci&oacute;n o incidencia que afecte la imparcialidad en el evento en que un usuario tome dichos servicios y requiera realizar la revisi&oacute;n tecno mec&aacute;nica y de emisiones contaminantes
      oficial y/o reinspeccion.</p>
    <p><br></p>
    <p dir="ltr"><strong>AUTOGASES DE COLOMBIA S.A</strong>.&nbsp;como Organismo de Inspecci&oacute;n ejecutar&aacute; todas sus actividades de acuerdo a los principios de buena fe, lealtad y honorabilidad se&ntilde;alados en la Constituci&oacute;n Nacional, el C&oacute;digo
      de Comercio y dem&aacute;s normas que las modifiquen o adicionen. En especial&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>.&nbsp;evitar&aacute; siempre la pr&aacute;ctica de conductas constitutivas de competencia desleal, o que busquen restringir
      la competencia.</p>
    <p><br></p>
    <p dir="ltr">Todas las actuaciones de&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>.&nbsp;como Organismo de Inspecci&oacute;n son p&uacute;blicas, en tal sentido&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>.&nbsp;se obliga a prestar total cooperaci&oacute;n a
      las autoridades competentes que as&iacute; lo requieran, siempre y cuando esto no contravenga nuestras obligaciones de confidencialidad de la informaci&oacute;n de los clientes.</p>
    <p><br></p>
    <p dir="ltr"><strong>POLITICAS DE CONFIDENCIALIDAD</strong>:&nbsp;Excepto que la Ley o las autoridades de acreditaci&oacute;n o las dem&aacute;s competentes lo requieran,&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>., tratar&aacute; de manera confidencial y no
      revelar&aacute; a terceros, sin previo consentimiento escrito del usuario, la informaci&oacute;n que repose en sus archivos o que fuere conocida y/o mantenida en posesi&oacute;n de sus funcionarios durante el curso de las inspecciones.</p>
    <p><br></p>
    <p dir="ltr">En ejercicio de la actividad de inspecci&oacute;n y por disposici&oacute;n legal,&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>,&nbsp;al momento de la recepci&oacute;n (orden de servicio) facturaci&oacute;n y registro del veh&iacute;culo o motocicleta,
      debe recaudar informaci&oacute;n personal del usuario, correspondiente al nombre y apellido del propietario y/o tenedor del veh&iacute;culo o motocicleta, n&uacute;mero de la c&eacute;dula de ciudadan&iacute;a, direcci&oacute;n, tel&eacute;fono, correo
      electr&oacute;nico, n&uacute;mero y categor&iacute;a de la licencia de conducci&oacute;n, seguro obligatorio y toda la informaci&oacute;n correspondiente al veh&iacute;culo o motocicleta. La informaci&oacute;n personal y t&eacute;cnica antes se&ntilde;alada,
      tiene como OBJETIVO, el registro y reporte ante el RUNT, SICOV- INDRA, ONAC, CORTOLIMA, SUPERPUESTOS, MINISTERIO DE TRANSPORTE y alimenta la base de datos de&nbsp;AUTOGASES DE COLOMBIA S.A,&nbsp;que la requiere para la expedici&oacute;n del certificado
      RTME y EC y/o formato uniforme de resultados de la revisi&oacute;n t&eacute;cnico mec&aacute;nica y de emisiones Contaminantes FUR; y realizar actividades de mercadeo, circunscritas a informar al usuario del vencimiento del certificado de revisi&oacute;n,
      tr&aacute;mites pendientes ante el RUNT, o los organismos de tr&aacute;nsito y/o del seguro obligatorio y cualquier otra informaci&oacute;n relevante que afecte o modifique, las condiciones del certificado de revisi&oacute;n t&eacute;cnico mec&aacute;nica
      y de emisiones contaminantes u ofertas que realiza&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>,&nbsp;como tambi&eacute;n para informar a los usuarios de los nuevos servicios y el vencimiento de estos. El usuario tendr&aacute; conocimiento del
      reporte de la informaci&oacute;n suministrada por &eacute;l y la acoplada durante el proceso de RTM y EC en el documento de Orden de Servicio toda la informaci&oacute;n registrada y reportada antes referenciadas est&aacute;n consignadas en el formulario
      Uniformes de Resultados de la Revisi&oacute;n T&eacute;cnico Mec&aacute;nica y de Emisiones Contaminantes FUR. Adem&aacute;s de lo anterior, autorizo a <strong>AUTOGASES DE COLOMBIA SA</strong>. a usar la informaci&oacute;n que reposa en este documento
      (orden de servicio) y el recibo del certificado de revisi&oacute;n t&eacute;cnico mec&aacute;nica, el sistema de registro y cualquier otro mecanismo de recepci&oacute;n de informaci&oacute;n, para hacer uso de los datos all&iacute; consignados, con
      el &uacute;nico fin de informarme sobre el vencimiento de la revisi&oacute;n t&eacute;cnico mec&aacute;nica, as&iacute; como de los cambios legales o reglamentarios que se produzcan al certificado o a la revisi&oacute;n y con fines comerciales en
      materia de precios y promociones de conformidad a la ley 1581 de 2012 Autogases se ha comprometido en el documento Pol&iacute;ticas de seguridad de la informaci&oacute;n a mantener la reserva que le se&ntilde;ala la ley, a no divulgar informaci&oacute;n
      del usuario si no en los casos que expresamente la ley determina y a darle el uso a la informaci&oacute;n que el usuario a autorizado.</p>
    <p><br></p>
    <p dir="ltr"><strong>EXPERIENCIA DEL ORGANISMO DE INSPECCI&Oacute;N</strong>:&nbsp;<strong>AUTOGASES DE COLOMBIA S.A</strong>., se encuentra habilitada desde el 26 de julio de 2007 para realizar las revisiones tecno mec&aacute;nicas y de emisiones contaminantes a
      veh&iacute;culos livianos y pesados mediante resoluci&oacute;n 2998 del 26 de Julio de 2007 y para revisi&oacute;n de motocicletas desde el 2 de Diciembre de 2011, seg&uacute;n resoluci&oacute;n No. 5372 expedidas por el Ministerio de Transporte.
      De igual manera se encuentra acreditada por la&nbsp;ONAC&nbsp;mediante certificado No. 09 OIN 043 expedida desde el 11 de septiembre de 2009 y actualmente vigente.</p>
    <p><br></p>
    <p dir="ltr"><strong>CONDICIONES CONTRACTUALES</strong>:&nbsp;Autorizo a Autogases de Colombia S.A. en su calidad de Organismo de Inspecci&oacute;n, para que lleve a cabo el servicio solicitado de acuerdo a las normas legales, reglamentarias y t&eacute;cnicas vigentes
      sobre la materia, y seg&uacute;n los reglamentos y procedimientos internos que el organismo de inspecci&oacute;n ha elaborado, de conformidad a la resoluci&oacute;n 3768 de 2013 expedida por el Ministerio de Transporte, las NTC 5375 y 53 85. Igualmente
      declaro y acepto que me han sido suficientemente explicadas las condiciones de cada una de las pruebas que componen los servicios solicitados y el alcance de las mismas; en especial lo referido a las pruebas de emisiones de gases contaminantes reguladas,
      por la resoluci&oacute;n 910 de 2008, expedida por el Ministerio de Ambiente y Desarrollo Territorial y en las NTC 4194 - 4231,4983 y 5365.</p>
    <p dir="ltr">Acepto que el veh&iacute;culo durante el transcurso de las inspecciones sea conducido por el personal t&eacute;cnico de Autogases de Colombia S.A. Para la prueba de emisiones contaminantes en veh&iacute;culo Di&eacute;sel, acepto y declaro que en caso
      de no tener instalado un sistema de gobernador en mi veh&iacute;culo, me han sido suficientemente explicadas las condiciones de la prueba y autorizo expresamente con mi firma en este documento la realizaci&oacute;n de la prueba como se establece en
      la NTC 4231 y asumo todas las consecuencias que se deriven de la misma.</p>
    <p dir="ltr">De conformidad a la resoluci&oacute;n 3768 de 2013 expedida por Ministerio de Transporte, cuando el veh&iacute;culo o motocicleta ha sido reprobado, para la re-inspecci&oacute;n que debe realizarse, previamente se har&aacute; una inspecci&oacute;n sensorial
      a fin de establecer que las condiciones iniciales referidas a la primera inspecci&oacute;n no se han modificado. En casos de evidenciarse cambios en esta inspecci&oacute;n sensorial, se proceder&aacute; a realizar nuevamente la inspecci&oacute;n t&eacute;cnico
      mec&aacute;nica completa, en cuyo caso el usuario deber&aacute; cancelar el valor correspondiente a la nueva inspecci&oacute;n. De no evidenciarse cambios a las condiciones iniciales de inspecci&oacute;n, se proceder&aacute; a inspeccionar aquellos
      &iacute;tems t&eacute;cnicos por los que r&eacute;probo inicialmente. La tarifa que se aplica para el servicio de la revisi&oacute;n T&eacute;cnico mec&aacute;nica es la determinada por la resoluci&oacute;n 3318 de 2015 del Ministerio de Transporte.
      El servicio se prestar&aacute; en el horario de lunes a Viernes de 7:a.m. a 6: 00 p.m. y s&aacute;bados de 7:00 a.m. a 5.00 p.m.</p>
    <p><br></p>
    <p dir="ltr"><strong>OBLIGACIONES DEL USUARIOS</strong>: Una vez se recepcione el veh&iacute;culo el usuario debe ubicarse en la sala de espera dispuesta por el CDA. - <strong>En el evento que el veh&iacute;culo sometido a la inspecci&oacute;n, repruebe algunos de los &iacute;tems de evaluaci&oacute;n, el usuario cuenta con 15 d&iacute;as calendario contados desde la fecha y hora del pago de la revisi&oacute;n Tecnicomecanica para realizar los arreglos pertinentes y presentarlo nuevamente a revisi&oacute;n sin costo adicional</strong>.
      Si los 15 d&iacute;as coinciden con un d&iacute;a dominical o feriado el usuario debe presentarse al CDA el d&iacute;a h&aacute;bil inmediatamente anterior. El pago de servicio de revisi&oacute;n t&eacute;cnico mec&aacute;nica no implica la expedici&oacute;n
      del certificado. Este solamente se expide si el veh&iacute;culo supera todas las pruebas objeto de revisi&oacute;n conforme los se&ntilde;alan los reglamentos t&eacute;cnicos. - Es deber del usuario abstenerse de dar dadivas, dinero u ofrecimientos
      indebidos al personal del CDA. Una vez que el veh&iacute;culo ha sido inspeccionado, no habr&aacute; devoluci&oacute;n de dinero. <strong>Pasados 15 d&iacute;as calendario desde la primera inspecci&oacute;n si el veh&iacute;culo no fue presentado para la reinspecci&oacute;n, o no aprueba &eacute;sta en el lapso de los 15 d&iacute;as se genera un NUEVO COBRO por el valor de la tarifa se&ntilde;alado por el Ministerio de Transporte, y el proceso se reinicia como si fuera por primera vez.</strong></p>
  </div>
</div>
</template>
<script>
  export default {
    name: 'q-contract',
  }
</script>

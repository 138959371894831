var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("q-btn", {
        attrs: { icon: "add", color: "primary", round: "" },
        on: {
          click: function($event) {
            _vm.modal = true
          }
        }
      }),
      _c(
        "q-modal",
        {
          model: {
            value: _vm.modal,
            callback: function($$v) {
              _vm.modal = $$v
            },
            expression: "modal"
          }
        },
        [
          _c("div", { staticClass: "layout-padding" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "q-field",
                    { attrs: { error: _vm.$v.form.brand_id.$error } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold d-inline-block",
                          class: { "color-danger": _vm.$v.form.brand_id.$error }
                        },
                        [_vm._v("\n              Marca:\n            ")]
                      ),
                      _c("q-select", {
                        staticClass: "q-mb-lg uppercase",
                        attrs: {
                          filter: "",
                          placeholder: "Marca",
                          options: _vm.$store.state.data.types_brands
                        },
                        model: {
                          value: _vm.form.brand_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "brand_id", $$v)
                          },
                          expression: "form.brand_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "q-field",
                    {
                      attrs: {
                        error: this.$v.form.name.$error,
                        "error-label": "Dato requerido"
                      }
                    },
                    [
                      _c("q-input", {
                        attrs: { "float-label": "Linea" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("q-btn", {
                    staticClass: "full-width",
                    attrs: { color: "primary", label: "Guardar" },
                    on: {
                      click: function($event) {
                        _vm.save()
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$store.state.data.load_inner,
          expression: "!$store.state.data.load_inner"
        }
      ],
      staticClass: "container-section q-py-lg"
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-8 col-md-7 col-lg-5 mx-auto" },
          [
            _c("div", { staticClass: "row q-pt-sm" }, [
              _c("div", { staticClass: "col-12 text-center" }, [
                _c("p", [_vm._v("Datos de Usuario")])
              ])
            ]),
            _c(
              "q-field",
              {
                staticClass: "q-mb-md",
                attrs: { error: _vm.$v.form.type_document.$error }
              },
              [
                _c("span", { staticClass: "w-50 d-inline-block" }, [
                  _vm._v(" Tipo de documento ")
                ]),
                _c(
                  "div",
                  { staticClass: "w-50 d-inline-block" },
                  [
                    _c("q-select", {
                      staticClass: "bg-white q-mb-md",
                      attrs: { options: _vm.selectOptions },
                      model: {
                        value: _vm.form.type_document,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type_document", $$v)
                        },
                        expression: "form.type_document"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "q-field",
              {
                staticClass: "q-my-md",
                attrs: { error: _vm.$v.form.number_document.$error }
              },
              [
                _c("q-input", {
                  staticClass: "bg-white mx-auto",
                  attrs: {
                    type: "text",
                    placeholder: "N° Documento",
                    after: [
                      {
                        icon: "search",
                        handler: function handler() {
                          _vm.searchUser()
                        }
                      }
                    ]
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchUser($event)
                    }
                  },
                  model: {
                    value: _vm.form.number_document,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "number_document", $$v)
                    },
                    expression: "form.number_document"
                  }
                }),
                _c(
                  "q-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$v.form.number_document.$error,
                        expression: "$v.form.number_document.$error"
                      }
                    ]
                  },
                  [
                    !_vm.$v.form.number_document.required
                      ? _c("p", { staticClass: "error-message mb-0" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("error_outline")
                          ]),
                          _vm._v(
                            " El campo es obligatorio.\n                    "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.form.number_document.minLength
                      ? _c("p", { staticClass: "error-message mb-0" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("error_outline")
                          ]),
                          _vm._v(
                            " Debe contener al menos 8 caracteres.\n                    "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _vm.form.type_document == "nit"
              ? _c(
                  "q-field",
                  {
                    staticClass: "q-my-md",
                    attrs: { error: _vm.$v.form.business.$error }
                  },
                  [
                    _c("q-input", {
                      staticClass: "bg-white mx-auto",
                      attrs: { type: "text", placeholder: "Empresa" },
                      model: {
                        value: _vm.form.business,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "business", $$v)
                        },
                        expression: "form.business"
                      }
                    }),
                    _c(
                      "q-tooltip",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$v.form.business.$error,
                            expression: "$v.form.business.$error"
                          }
                        ]
                      },
                      [
                        !_vm.$v.form.business.required
                          ? _c("p", { staticClass: "error-message mb-0" }, [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("error_outline")
                              ]),
                              _vm._v(
                                " El campo es obligatorio.\n                    "
                              )
                            ])
                          : _vm._e(),
                        !_vm.$v.form.business.minLength
                          ? _c("p", { staticClass: "error-message mb-0" }, [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("error_outline")
                              ]),
                              _vm._v(
                                " Debe contener al menos 8 caracteres.\n                    "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "q-field",
              {
                staticClass: "q-my-md",
                attrs: {
                  error: _vm.$v.form.first_name.$error,
                  "error-label": ""
                }
              },
              [
                _c("q-input", {
                  staticClass: "bg-white mx-auto",
                  attrs: { type: "text", placeholder: "Nombre" },
                  model: {
                    value: _vm.form.first_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "first_name", $$v)
                    },
                    expression: "form.first_name"
                  }
                }),
                _c(
                  "q-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$v.form.first_name.$error,
                        expression: "$v.form.first_name.$error"
                      }
                    ]
                  },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.form.first_name.required,
                            expression: "!$v.form.first_name.required"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons color-danger" },
                          [_vm._v(" error_outline ")]
                        ),
                        _vm._v(
                          " El campo es obligatorio.\n                    "
                        )
                      ]
                    ),
                    !_vm.$v.form.first_name.minLength
                      ? _c("p", { staticClass: "error-message mb-0" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("error_outline")
                          ]),
                          _vm._v(
                            " Debe contener al menos 3 caracteres.\n                    "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c(
              "q-field",
              {
                staticClass: "q-my-md",
                attrs: {
                  error: _vm.$v.form.last_name.$error,
                  "error-label": ""
                }
              },
              [
                _c("q-input", {
                  staticClass: "bg-white mx-auto",
                  attrs: { type: "text", placeholder: "Apellido" },
                  model: {
                    value: _vm.form.last_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "last_name", $$v)
                    },
                    expression: "form.last_name"
                  }
                }),
                _c(
                  "q-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$v.form.last_name.$error,
                        expression: "$v.form.last_name.$error"
                      }
                    ]
                  },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.form.last_name.required,
                            expression: "!$v.form.last_name.required"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons color-danger" },
                          [_vm._v(" error_outline ")]
                        ),
                        _vm._v(
                          " El campo es obligatorio.\n                    "
                        )
                      ]
                    ),
                    !_vm.$v.form.last_name.minLength
                      ? _c("p", { staticClass: "error-message mb-0" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("error_outline")
                          ]),
                          _vm._v(
                            " Debe contener al menos 3 caracteres.\n                    "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c(
              "q-field",
              {
                staticClass: "q-my-md",
                attrs: { error: _vm.$v.form.phone.$error, "error-label": "" }
              },
              [
                _c("q-input", {
                  staticClass: "bg-white mx-auto",
                  attrs: { type: "text", placeholder: "Celular" },
                  model: {
                    value: _vm.form.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone"
                  }
                }),
                _c(
                  "q-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$v.form.phone.$error,
                        expression: "$v.form.phone.$error"
                      }
                    ]
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.form.phone.required,
                            expression: "!$v.form.phone.required"
                          }
                        ]
                      },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons color-danger" },
                          [_vm._v(" error_outline ")]
                        ),
                        _vm._v(" El campo es obligatorio.\n                ")
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "q-field",
              {
                staticClass: "q-my-md",
                attrs: { error: _vm.$v.form.email.$error, "error-label": "" }
              },
              [
                _c("q-input", {
                  staticClass: "bg-white mx-auto",
                  attrs: { type: "text", placeholder: "Correo eléctronico" },
                  model: {
                    value: _vm.form.email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email"
                  }
                }),
                _c(
                  "q-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$v.form.email.$error,
                        expression: "$v.form.email.$error"
                      }
                    ]
                  },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.form.email.required,
                            expression: "!$v.form.email.required"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v(" error_outline ")
                        ]),
                        _vm._v(
                          " El campo es obligatorio.\n                    "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.form.email.email,
                            expression: "!$v.form.email.email"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("error_outline")
                        ]),
                        _vm._v(
                          " Debe ingresar un email valido.\n                    "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "q-field",
              { staticClass: "q-my-md" },
              [
                _c("q-input", {
                  staticClass: "bg-white mx-auto",
                  attrs: { type: "text", placeholder: "Dirección" },
                  model: {
                    value: _vm.form.address,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "address", $$v)
                    },
                    expression: "form.address"
                  }
                })
              ],
              1
            ),
            _c(
              "q-field",
              {
                staticClass: "q-my-md",
                attrs: { error: _vm.$v.form.checked.$error, "error-label": "" }
              },
              [
                _c("q-checkbox", {
                  staticClass: "mx-auto",
                  class: { "color-danger": _vm.$v.form.checked.$error },
                  attrs: { label: "Autorizo la toma de datos personales" },
                  model: {
                    value: _vm.form.checked,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "checked", $$v)
                    },
                    expression: "form.checked"
                  }
                }),
                _c(
                  "q-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$v.form.checked.$error,
                        expression: "$v.form.checked.$error"
                      }
                    ]
                  },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.form.checked.sameAs,
                            expression: "!$v.form.checked.sameAs"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("error_outline")
                        ]),
                        _vm._v(
                          "Debe autorizar los datos.\n                    "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                this.form.user_id == null
                  ? _c("q-btn", {
                      staticClass: "q-px-lg q-mx-sm btn-app",
                      attrs: { color: "black", size: "md", label: "ENVIAR" },
                      on: { click: _vm.submit }
                    })
                  : _vm._e(),
                this.form.user_id !== null
                  ? _c("q-btn", {
                      staticClass: "q-px-lg q-mx-sm btn-app",
                      attrs: {
                        color: "black",
                        size: "md",
                        label: "Actualizar"
                      },
                      on: { click: _vm.update }
                    })
                  : _vm._e(),
                this.form.user_id !== null
                  ? _c("q-btn", {
                      staticClass: "q-px-lg q-mx-sm btn-app",
                      attrs: { color: "black", size: "md", label: "Continuar" },
                      on: { click: _vm.toCreateInspection }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
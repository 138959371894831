var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "q-item",
        { attrs: { link: "", to: { name: _vm.item.to } } },
        [
          _vm.item.icon
            ? _c("q-item-side", { attrs: { icon: _vm.item.icon } })
            : _vm._e(),
          _c("q-item-main", [_vm._v(_vm._s(_vm.item.title))])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
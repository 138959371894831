var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$store.state.data.load_inner,
          expression: "!$store.state.data.load_inner"
        }
      ]
    },
    [
      _c(
        "q-page",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showContract && !_vm.showsignature,
              expression: "!showContract && !showsignature"
            }
          ]
        },
        [
          _c("div", { staticClass: "row q-border" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-10 col-md-6 mx-auto q-px-md" },
              [
                _c(
                  "span",
                  { staticClass: "w-50 d-inline-block font-weight-bold" },
                  [
                    _vm._v(
                      "\n                    TIPO DE INSPECCIÓN:\n                "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "w-50 d-inline-block" },
                  [
                    _c("q-select", {
                      staticClass: "bg-white q-py-sm q-my-md",
                      attrs: {
                        filter: "",
                        options: _vm.typesReinspectionsFiltered
                      },
                      model: {
                        value: _vm.data.inspections_types_id,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "inspections_types_id", $$v)
                        },
                        expression: "data.inspections_types_id"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "w-50 d-inline-block" }),
                _vm.data.inspections_types_id == 3
                  ? _c(
                      "div",
                      { staticClass: "w-50 d-inline-block" },
                      [
                        _c("q-select", {
                          staticClass: "bg-white q-py-sm q-my-md",
                          attrs: {
                            multiple: "",
                            filter: "",
                            chips: "",
                            options: _vm.$store.getters["data/GET_TEST"]
                          },
                          model: {
                            value: _vm.data.test,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "test", $$v)
                            },
                            expression: "data.test"
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s((_vm.data.test = [])) +
                            "\n                "
                        )
                      ]
                    )
              ]
            )
          ]),
          _c("div", { staticClass: "row q-py-lg" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-10 col-md-4 mx-auto q-px-md" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 text-center font-weight-bold" },
                    [_c("p", [_vm._v("Datos del Vehículo")])]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "q-field",
                        { attrs: { error: _vm.$v.formSearch.plaque.$error } },
                        [
                          _c("q-input", {
                            staticClass: "bg-white q-mt-sm uppercase",
                            attrs: {
                              type: "text",
                              placeholder: "Placa",
                              after: [
                                {
                                  icon: "search",
                                  handler: function handler() {
                                    _vm.searchPlaque()
                                  }
                                }
                              ]
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchPlaque($event)
                              }
                            },
                            model: {
                              value: _vm.formSearch.plaque,
                              callback: function($$v) {
                                _vm.$set(_vm.formSearch, "plaque", $$v)
                              },
                              expression: "formSearch.plaque"
                            }
                          }),
                          _c(
                            "q-tooltip",
                            {
                              attrs: { error: _vm.$v.formSearch.plaque.$error }
                            },
                            [
                              !_vm.$v.formSearch.plaque.required
                                ? _c(
                                    "p",
                                    { staticClass: "error-message mb-0" },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("error_outline")]
                                      ),
                                      _vm._v(
                                        " El campo es obligatorio.\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.$v.formSearch.plaque.minLength
                                ? _c(
                                    "p",
                                    { staticClass: "error-message mb-0" },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("error_outline")]
                                      ),
                                      _vm._v(
                                        " Debe contener al menos 5 caracteres.\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notFound,
                  expression: "notFound"
                }
              ],
              staticClass: "row"
            },
            [
              _c(
                "div",
                { staticClass: "col-12 col-sm-10 col-md-8 mx-auto q-px-md" },
                [
                  _c("q-alert", {
                    attrs: {
                      icon: "error",
                      color: "warning",
                      message: "LA PLACA NO FUE ENCONTRADA",
                      detail: _vm._f("uppercase")(
                        "SE CREARA UN NUEVO VEHICULO CON LA PLACA " +
                          _vm.formSearch.plaque
                      )
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "q-page",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showData,
                  expression: "showData"
                }
              ]
            },
            [
              _c("div", { staticClass: "q-my-lg q-pt-lg" }, [
                _vm.user
                  ? _c("div", { staticClass: "container-fluid bg-gray-10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row q-py-sm",
                          staticStyle: { "background-color": "#88AE3E" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col q-px-md text-center" },
                            [
                              _c("div", { staticClass: "q-py-sm q-px-sm" }, [
                                _vm._v(
                                  "\n                                Cliente: "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-light font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.user.first_name +
                                          " " +
                                          _vm.user.last_name
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "q-py-sm q-px-sm" }, [
                                _vm._v(
                                  "\n                                Email: "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-light font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.user.email ? _vm.user.email : "N/D"
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "q-py-sm q-px-sm" }, [
                                _vm._v(
                                  "\n                                Teléfono: "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-light font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.user.phone ? _vm.user.phone : "N/D"
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "q-py-sm q-px-sm" }, [
                                _vm._v(
                                  "\n                                Direccion: "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-light font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.user.address
                                          ? _vm.user.address
                                          : "N/D"
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm.data.attributes
                        ? _c("div", { staticClass: "row align-items-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-3 px-2 py-3 bg-primary text-right"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "h2 font-weight-bold my-3 d-block text-white"
                                  },
                                  [_vm._v("SOAT")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-4 col-md-2 mx-auto py-3 text-center"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "font-weight-bold font-famili"
                                  },
                                  [_c("b", [_vm._v("Fecha de Expedición")])]
                                ),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.data.attributes.insurance_expedition
                                        ? _vm.data.attributes
                                            .insurance_expedition
                                        : "N/D"
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-4 col-md-3 mx-auto py-3 text-center"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "font-weight-bold font-famili"
                                  },
                                  [
                                    _c("b", [
                                      _vm._v("Fecha Inicio De Vigencia")
                                    ])
                                  ]
                                ),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.data.attributes.insurance_expedition
                                        ? _vm.expedition(
                                            _vm.data.attributes
                                              .insurance_expedition
                                          )
                                        : "N/D"
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-4 col-md-2 mx-auto py-3 text-center"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "font-weight-bold font-famili"
                                  },
                                  [_c("b", [_vm._v("Fecha Fin De Vigencia")])]
                                ),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.data.attributes.insurance_expiration
                                        ? _vm.data.attributes
                                            .insurance_expiration
                                        : "N/D"
                                    )
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-4 col-md-2 mx-auto py-3 text-center"
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "font-weight-bold font-famili"
                                  },
                                  [_c("b", [_vm._v("Estado")])]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "mb-0 text-uppercase",
                                    class: {
                                      "text-green": _vm.validity(
                                        _vm.data.attributes.insurance_expiration
                                      ),
                                      "text-red": !_vm.validity(
                                        _vm.data.attributes.insurance_expiration
                                      )
                                    }
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("validity")(
                                            _vm.data.attributes
                                              .insurance_expiration
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.data.attributes
                  ? _c("div", { staticClass: "layout-padding q-py-lg" }, [
                      _c("div", { staticClass: "row mt-3 q-border" }, [
                        _c("div", { staticClass: "col-12 col-md-6 q-px-md" }, [
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Tipo de Servicio:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.service_type_text
                                      ? _vm.data.attributes.service_type_text
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Clase de Vehículo:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.type_vehicle_text
                                      ? _vm.data.attributes.type_vehicle_text
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Marca:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.brand
                                      ? _vm.data.attributes.brand
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Línea:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.line
                                      ? _vm.data.attributes.line
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Licencia de Tránsito:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.transit_license
                                      ? _vm.data.attributes.transit_license
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Fecha de Matrícula:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.enrollment_date
                                      ? _vm.data.attributes.enrollment_date
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col-12 col-md-6 q-px-md" }, [
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Color:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.color
                                      ? _vm.data.attributes.color
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Tipo de combustible:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.type_fuel_text
                                      ? _vm.data.attributes.type_fuel_text
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("N°Vin:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.vin_number
                                      ? _vm.data.attributes.vin_number
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("N° de Chasis:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.chasis_number
                                      ? _vm.data.attributes.chasis_number
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("N° de Motor:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.engine_number
                                      ? _vm.data.attributes.engine_number
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-block q-mb-lg q-mt-sm" },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("Cilindraje:")
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.data.attributes.displacement
                                      ? _vm.data.attributes.displacement
                                      : "N/D"
                                  ) +
                                  "\n                            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          !_vm.isMotocicleta()
                            ? _c(
                                "div",
                                { staticClass: "col-12 q-px-md q-border" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-4 col-md-3 col-lg-2 q-mb-lg q-mt-sm"
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.$v.data.type_vehicle
                                                    .$error,
                                                expression:
                                                  "$v.data.type_vehicle.$error"
                                              }
                                            ],
                                            staticClass:
                                              "material-icons color-danger q-mr-xs"
                                          },
                                          [_vm._v(" error_outline ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-weight-bold",
                                            class: {
                                              "color-danger":
                                                _vm.$v.data.type_vehicle.$error
                                            }
                                          },
                                          [_vm._v("Tipo de Vehículo:")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-8 col-md-9 col-lg-10"
                                      },
                                      [
                                        _c(
                                          "q-btn-group",
                                          { staticClass: "bg-white" },
                                          [
                                            _c("q-btn", {
                                              class:
                                                _vm.data.type_vehicle == "Heavy"
                                                  ? "bg-primary text-white"
                                                  : "",
                                              attrs: { label: "PESADO" },
                                              on: {
                                                click: function($event) {
                                                  _vm.data.type_vehicle =
                                                    "Heavy"
                                                }
                                              }
                                            }),
                                            _c("q-btn", {
                                              class:
                                                _vm.data.type_vehicle == "Light"
                                                  ? "bg-primary text-white"
                                                  : "",
                                              attrs: { label: "LIVIANO" },
                                              on: {
                                                click: function($event) {
                                                  _vm.data.type_vehicle =
                                                    "Light"
                                                }
                                              }
                                            }),
                                            _c("q-btn", {
                                              class:
                                                _vm.data.type_vehicle ==
                                                "Motorcycle"
                                                  ? "bg-primary text-white"
                                                  : "",
                                              attrs: { label: "MOTOCICLETA" },
                                              on: {
                                                click: function($event) {
                                                  _vm.data.type_vehicle =
                                                    "Motorcycle"
                                                }
                                              }
                                            }),
                                            false
                                              ? _c("q-btn", {
                                                  class:
                                                    _vm.data.type_vehicle ==
                                                    "Car"
                                                      ? "bg-primary text-white"
                                                      : "",
                                                  attrs: { label: "CARRO" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.data.type_vehicle =
                                                        "Car"
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "col-12 q-px-md q-border" },
                            [
                              _c(
                                "q-field",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    error: _vm.$v.data.teaching_vehicle.$invalid
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block label-invalid"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "q-mr-lg font-weight-bold"
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.$v.data.teaching_vehicle
                                                      .$error,
                                                  expression:
                                                    "$v.data.teaching_vehicle.$error"
                                                }
                                              ],
                                              staticClass:
                                                "material-icons color-danger q-mr-xs"
                                            },
                                            [_vm._v(" error_outline ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "color-danger":
                                                  _vm.$v.data.teaching_vehicle
                                                    .$error
                                              }
                                            },
                                            [_vm._v("Vehículo de Enseñanza:")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-inline-block" },
                                    [
                                      _c("q-radio", {
                                        staticClass: "q-mr-lg",
                                        attrs: { val: true, label: "Si" },
                                        model: {
                                          value: _vm.data.teaching_vehicle,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "teaching_vehicle",
                                              $$v
                                            )
                                          },
                                          expression: "data.teaching_vehicle"
                                        }
                                      }),
                                      _c("q-radio", {
                                        staticClass: "q-mr-lg",
                                        attrs: { val: false, label: "No" },
                                        model: {
                                          value: _vm.data.teaching_vehicle,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "teaching_vehicle",
                                              $$v
                                            )
                                          },
                                          expression: "data.teaching_vehicle"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-tooltip",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.$v.data.teaching_vehicle.$error,
                                          expression:
                                            "$v.data.teaching_vehicle.$error"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.$v.data
                                                .teaching_vehicle.required,
                                              expression:
                                                "!$v.data.teaching_vehicle.required"
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons color-danger"
                                            },
                                            [_vm._v(" error_outline ")]
                                          ),
                                          _vm._v(
                                            " El campo es obligatorio.\n                                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.isMotocicleta() &&
                          _vm.data.type_vehicle != "Motorcycle"
                            ? _c(
                                "div",
                                { staticClass: "col-12 q-px-md q-border" },
                                [
                                  false
                                    ? _c("q-checkbox", {
                                        staticClass: "q-mr-lg",
                                        attrs: {
                                          label: "Gobernador",
                                          "left-label": true
                                        },
                                        model: {
                                          value: _vm.data.governor,
                                          callback: function($$v) {
                                            _vm.$set(_vm.data, "governor", $$v)
                                          },
                                          expression: "data.governor"
                                        }
                                      })
                                    : _vm._e(),
                                  _c("q-checkbox", {
                                    staticClass: "q-mr-lg",
                                    attrs: {
                                      label: "Taxímetro",
                                      "left-label": true
                                    },
                                    model: {
                                      value: _vm.data.taximeter,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data, "taximeter", $$v)
                                      },
                                      expression: "data.taximeter"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isMotocicleta() &&
                          _vm.data.type_vehicle != "Motorcycle"
                            ? _c("div", { staticClass: "col-12 q-border" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12 q-px-md" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-inline-block q-mr-lg font-weight-bold"
                                      },
                                      [_vm._v("Vehículo a Gas:")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-inline-block" },
                                      [
                                        _c("q-radio", {
                                          staticClass: "q-mr-lg",
                                          attrs: { val: true, label: "Si" },
                                          model: {
                                            value: _vm.is_vehicle_gas,
                                            callback: function($$v) {
                                              _vm.is_vehicle_gas = $$v
                                            },
                                            expression: "is_vehicle_gas"
                                          }
                                        }),
                                        _c("q-radio", {
                                          staticClass: "q-mr-lg",
                                          attrs: { val: false, label: "No" },
                                          model: {
                                            value: _vm.is_vehicle_gas,
                                            callback: function($$v) {
                                              _vm.is_vehicle_gas = $$v
                                            },
                                            expression: "is_vehicle_gas"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.is_vehicle_gas,
                                        expression: "is_vehicle_gas"
                                      }
                                    ],
                                    staticClass: "row"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-sm-6 q-my-md q-px-sm"
                                      },
                                      [
                                        _c(
                                          "q-field",
                                          {
                                            attrs: {
                                              error:
                                                _vm.$v.data.gas_certificate
                                                  .$error
                                            }
                                          },
                                          [
                                            _c("q-input", {
                                              staticClass: "bg-white",
                                              attrs: {
                                                placeholder:
                                                  "Certiﬁcado de Gas N°"
                                              },
                                              model: {
                                                value:
                                                  _vm.$v.data.gas_certificate
                                                    .$model,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.$v.data.gas_certificate,
                                                    "$model",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "$v.data.gas_certificate.$model"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-sm-6 q-my-md q-px-sm"
                                      },
                                      [
                                        _c(
                                          "q-field",
                                          {
                                            attrs: {
                                              error:
                                                _vm.$v.data.gas_certifier.$error
                                            }
                                          },
                                          [
                                            _c("q-input", {
                                              staticClass: "bg-white",
                                              attrs: {
                                                placeholder: "Certiﬁcador"
                                              },
                                              model: {
                                                value:
                                                  _vm.$v.data.gas_certifier
                                                    .$model,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.$v.data.gas_certifier,
                                                    "$model",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "$v.data.gas_certifier.$model"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-sm-6 q-my-md q-px-sm"
                                      },
                                      [
                                        _c(
                                          "q-field",
                                          {
                                            attrs: {
                                              error:
                                                _vm.$v.data
                                                  .gas_certificate_expiration
                                                  .$error
                                            }
                                          },
                                          [
                                            _c("q-datetime", {
                                              staticClass: "bg-white",
                                              attrs: {
                                                type: "date",
                                                placeholder:
                                                  "Fecha de Vencimiento",
                                                format: "YYYY-MM-DD"
                                              },
                                              model: {
                                                value:
                                                  _vm.$v.data
                                                    .gas_certificate_expiration
                                                    .$model,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.$v.data
                                                      .gas_certificate_expiration,
                                                    "$model",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "$v.data.gas_certificate_expiration.$model"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "col-12 q-border" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 q-my-sm q-px-md" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold q-mb-sm d-block"
                                        },
                                        [_vm._v("# Cilindros Motor:")]
                                      ),
                                      _c(
                                        "q-field",
                                        [
                                          _c("q-input", {
                                            staticClass: "bg-white q-my-sm",
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              placeholder: "# Cilindros Motor"
                                            },
                                            model: {
                                              value: _vm.data.engine_cylinders,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "engine_cylinders",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.engine_cylinders"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 q-my-sm q-px-md" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold q-mb-sm d-block"
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.$v.data.mileage.$error,
                                                  expression:
                                                    "$v.data.mileage.$error"
                                                }
                                              ],
                                              staticClass:
                                                "material-icons color-danger q-mr-xs"
                                            },
                                            [_vm._v(" error_outline ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "color-danger":
                                                  _vm.$v.data.mileage.$error
                                              }
                                            },
                                            [_vm._v("Kilometraje:")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "q-field",
                                            {
                                              staticClass: "q-my-xs",
                                              attrs: {
                                                error:
                                                  _vm.$v.data.mileage.$error
                                              }
                                            },
                                            [
                                              _c("q-input", {
                                                staticClass: "bg-white q-my-sm",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  placeholder: "Kilometraje"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.data.mileage.$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.data.mileage,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.data.mileage.$model"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("q-tooltip", [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.$v.data.mileage
                                                      .required,
                                                    expression:
                                                      "!$v.data.mileage.required"
                                                  }
                                                ]
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons"
                                                  },
                                                  [_vm._v(" error_outline ")]
                                                ),
                                                _vm._v(
                                                  " El campo es obligatorio."
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _vm.data.type_vehicle === "Motorcycle"
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 q-my-sm q-px-md"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold q-mb-sm d-block"
                                            },
                                            [_vm._v("# Numero Exhosto:")]
                                          ),
                                          _c(
                                            "q-field",
                                            [
                                              _c("q-input", {
                                                staticClass: "bg-white q-my-sm",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  placeholder:
                                                    "# Numero Exhosto:"
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.exhosto_number,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "exhosto_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.exhosto_number"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e(),
                                !_vm.isMotocicleta() &&
                                _vm.data.type_vehicle != "Motorcycle"
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 q-my-sm q-px-md"
                                        },
                                        [
                                          _c(
                                            "q-field",
                                            [
                                              _c("q-checkbox", {
                                                attrs: {
                                                  "left-label": true,
                                                  label: "Vidrios Polarizados: "
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.polarized_glasses,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "polarized_glasses",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.polarized_glasses"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 q-my-sm q-px-md"
                                        },
                                        [
                                          _c(
                                            "q-field",
                                            [
                                              _c("q-checkbox", {
                                                attrs: {
                                                  "left-label": true,
                                                  label: "Vehículo Blindado: "
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.armored_vehicle,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "armored_vehicle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.armored_vehicle"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 q-my-sm q-px-md"
                                        },
                                        [
                                          _c(
                                            "q-field",
                                            [
                                              _c("q-checkbox", {
                                                attrs: {
                                                  "left-label": true,
                                                  label: "Motor Modiﬁcado: "
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.modified_engine,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "modified_engine",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.modified_engine"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12 q-my-sm q-px-md"
                                        },
                                        [
                                          _c(
                                            "q-field",
                                            { staticClass: "d-inline-block" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold q-mr-md q-mt-xs"
                                                },
                                                [_vm._v("Llanta de repuesto:")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-inline-block"
                                                },
                                                [
                                                  _c("q-input", {
                                                    staticClass: "bg-white",
                                                    staticStyle: {
                                                      "max-width": "47px"
                                                    },
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value:
                                                        _vm.data.spare_tires,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.data,
                                                          "spare_tires",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.spare_tires"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12 row q-border" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 q-px-md q-mb-md text-center"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("Pre-Inspección")]
                                  )
                                ]
                              ),
                              _vm._l(_vm.data.pre_inspections, function(
                                pre_inspection,
                                item
                              ) {
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-sm-6 q-my-md q-px-md"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-inline-block font-weight-bold q-mr-lg"
                                      },
                                      [_vm._v(_vm._s(pre_inspection.name))]
                                    ),
                                    !pre_inspection.options
                                      ? _c(
                                          "div",
                                          { staticClass: "d-inline-block" },
                                          [
                                            pre_inspection.type == "text"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("q-input", {
                                                      attrs: { type: "number" },
                                                      model: {
                                                        value:
                                                          pre_inspection.value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pre_inspection,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "pre_inspection.value"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("q-radio", {
                                                      staticClass: "q-mr-lg",
                                                      attrs: {
                                                        val: true,
                                                        label: "Si"
                                                      },
                                                      model: {
                                                        value:
                                                          pre_inspection.value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pre_inspection,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "pre_inspection.value"
                                                      }
                                                    }),
                                                    _c("q-radio", {
                                                      staticClass: "q-mr-lg",
                                                      attrs: {
                                                        val: false,
                                                        label: "No"
                                                      },
                                                      model: {
                                                        value:
                                                          pre_inspection.value,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pre_inspection,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "pre_inspection.value"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("q-select", {
                                              staticClass:
                                                "bg-white q-py-sm q-my-md",
                                              attrs: {
                                                options: pre_inspection.options,
                                                placeholder: pre_inspection.name
                                              },
                                              model: {
                                                value: pre_inspection.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    pre_inspection,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "pre_inspection.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c("q-axes", {
                            staticClass: "col-12 q-px-md q-border",
                            attrs: {
                              axes: _vm.$v.data.axes,
                              type: _vm.data.type_vehicle == "Motorcycle"
                            }
                          }),
                          _c("q-inventary", {
                            staticClass: "col-12 q-px-md q-border text-center",
                            attrs: {
                              inventory: _vm.$v.data.items,
                              itemsRequired: _vm.$v.data.itemsRequired.$error
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "col-12 q-px-md" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-bold q-px-md q-pt-md"
                                },
                                [_vm._v("Fotografías")]
                              ),
                              _c(
                                "carousel",
                                {
                                  attrs: {
                                    perPage: 3,
                                    paginationEnabled: false,
                                    autoplay: true,
                                    navigationEnabled: true,
                                    navigationNextLabel: _vm.nextLabel,
                                    navigationPrevLabel: _vm.prevLabel
                                  }
                                },
                                _vm._l(_vm.data.gallery, function(img, index) {
                                  return _c("slide", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "q-px-md text-center" },
                                      [
                                        _c("img", {
                                          staticStyle: { "max-width": "100%" },
                                          attrs: { src: _vm._f("asset")(img) }
                                        })
                                      ]
                                    )
                                  ])
                                })
                              )
                            ],
                            1
                          ),
                          _c("galeria", {
                            staticClass: "col-12 q-px-md",
                            attrs: {
                              gallery: _vm.data.gallery,
                              code: _vm.data.code
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "col-12 q-my-md" },
                            [
                              _c(
                                "q-field",
                                {
                                  attrs: {
                                    error: _vm.$v.data.observations.$error
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-bold d-block",
                                      class: {
                                        "color-danger":
                                          _vm.$v.data.observations.$error
                                      }
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.$v.data.observations.$error,
                                              expression:
                                                "$v.data.observations.$error"
                                            }
                                          ],
                                          staticClass:
                                            "material-icons color-danger q-mr-xs"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            error_outline\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                        OBSERVACIONES:\n                                    "
                                      )
                                    ]
                                  ),
                                  _c("q-input", {
                                    staticClass: "bg-white",
                                    attrs: {
                                      type: "textarea",
                                      "max-height": 10,
                                      rows: "4"
                                    },
                                    model: {
                                      value: _vm.data.observations,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data, "observations", $$v)
                                      },
                                      expression: "data.observations"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 text-right" },
                            [
                              _c("q-btn", {
                                staticClass: "q-px-lg btn-app",
                                attrs: {
                                  color: "black",
                                  size: "md",
                                  label: "Guardar"
                                },
                                on: { click: _vm.submitData }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "q-page",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showContract,
              expression: "showContract"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "layout-padding q-py-lg" },
            [
              _c("q-contract"),
              _c(
                "div",
                { staticClass: "col-12 q-my-md" },
                [
                  _c("q-checkbox", {
                    attrs: {
                      label:
                        "Autorizo al centro de diagnostico para que utilice mis datos personales con ﬁnes comerciales"
                    },
                    model: {
                      value: _vm.aceptContract,
                      callback: function($$v) {
                        _vm.aceptContract = $$v
                      },
                      expression: "aceptContract"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 text-right q-my-md" },
                [
                  _c("q-btn", {
                    staticClass: "q-px-lg q-mx-sm btn-app",
                    attrs: { color: "red", size: "md", label: "Atras" },
                    on: {
                      click: function($event) {
                        _vm.back(1)
                      }
                    }
                  }),
                  _c("q-btn", {
                    staticClass: "q-px-lg btn-app",
                    attrs: { color: "black", size: "md", label: "Acepto" },
                    on: { click: _vm.submitContract }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "q-page",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showsignature,
              expression: "showsignature"
            }
          ]
        },
        [
          _c("div", { staticClass: "layout-padding q-py-lg" }, [
            _c("div", { staticClass: "row q-my-lg" }, [
              _c("hr", { staticClass: "col-12 q-my-lg" }),
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("p", { staticClass: "font-weight-bold" }, [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$v.data.vehicle_prepared.$error,
                              expression: "$v.data.vehicle_prepared.$error"
                            }
                          ],
                          staticClass: "material-icons color-danger q-mr-xs"
                        },
                        [_vm._v(" error_outline ")]
                      ),
                      _c(
                        "span",
                        {
                          class: {
                            "color-danger": _vm.$v.data.vehicle_prepared.$error
                          }
                        },
                        [_vm._v("Vehículo preparado para la inspección:")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      { staticClass: "d-inline-block" },
                      [
                        _c("q-radio", {
                          staticClass: "q-mr-lg",
                          attrs: { val: true, label: "Si" },
                          model: {
                            value: _vm.data.vehicle_prepared,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "vehicle_prepared", $$v)
                            },
                            expression: "data.vehicle_prepared"
                          }
                        }),
                        _c("q-radio", {
                          staticClass: "q-mr-lg",
                          attrs: { val: false, label: "No" },
                          model: {
                            value: _vm.data.vehicle_prepared,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "vehicle_prepared", $$v)
                            },
                            expression: "data.vehicle_prepared"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row print-row" }, [
                  _c("div", { staticClass: "col-12 print-col-12" }, [
                    _c(
                      "p",
                      {
                        staticClass: "font-weight-bold d-inline-block q-mr-md",
                        class: {
                          "border-danger":
                            _vm.$v.data.seen_technical_director.$error
                        }
                      },
                      [
                        _vm._v(
                          "\n                            Visto bueno director técnico:\n                            "
                        ),
                        _c("span", { staticClass: "d-none" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.data.seen_technical_director ? "SI" : "NO"
                              ) +
                              "\n                            "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-inline-block print-none" },
                      [
                        _c("q-radio", {
                          staticClass: "q-mr-lg",
                          attrs: {
                            disable:
                              !_vm.is_signature_received_report &&
                              _vm.data.seen_technical_director != null,
                            val: 1,
                            label: "Si"
                          },
                          model: {
                            value: _vm.data.seen_technical_director,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "seen_technical_director", $$v)
                            },
                            expression: "data.seen_technical_director"
                          }
                        }),
                        _c("q-radio", {
                          staticClass: "q-mr-lg",
                          attrs: {
                            disable:
                              !_vm.is_signature_received_report &&
                              _vm.data.seen_technical_director != null,
                            val: 0,
                            label: "No"
                          },
                          model: {
                            value: _vm.data.seen_technical_director,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "seen_technical_director", $$v)
                            },
                            expression: "data.seen_technical_director"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 q-mb-lg" },
                [
                  _c("div", { staticClass: "row q-mb-sm" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("p", { staticClass: "font-weight-bold mb-0" }, [
                        _vm._v("Firma y Cédula Entrega de Vehículo")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col text-right" },
                      [
                        _vm.data.vehicle_delivery_signature
                          ? _c("q-btn", {
                              staticClass: "q-px-lg btn-app",
                              attrs: {
                                color: "black",
                                size: "sm",
                                label: "Limpiar"
                              },
                              on: { click: _vm.undo }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm.showsignature && _vm.is_vehicle_delivery_signature
                    ? _c("VueSignaturePad", {
                        ref: "signatureEntrega",
                        staticClass: "border-bottom signatured",
                        class: {
                          "border-danger":
                            _vm.$v.data.vehicle_delivery_signature.$error
                        },
                        attrs: {
                          width: "100%",
                          height: "200px",
                          disable: !_vm.is_vehicle_delivery_signature,
                          options: { onEnd: _vm.onEnd }
                        }
                      })
                    : _c("img", {
                        staticStyle: {
                          border: "2px solid #0c0c0c",
                          "border-radius": "8px"
                        },
                        attrs: {
                          src: _vm.data.vehicle_delivery_signature,
                          width: "100%",
                          height: "200px"
                        }
                      }),
                  _c(
                    "p",
                    {
                      staticClass: "font-weight-bold",
                      class: {
                        "color-danger":
                          _vm.$v.data.vehicle_delivery_signature.$error
                      }
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$v.data.vehicle_delivery_signature.$error,
                              expression:
                                "$v.data.vehicle_delivery_signature.$error"
                            }
                          ],
                          staticClass: "material-icons color-danger q-mr-xs"
                        },
                        [_vm._v(" error_outline ")]
                      ),
                      _vm._v(
                        "\n                        Acepto y estoy conforme con el inventario realizado a mí Vehículo y he leído, entiendo y acepto todas las oservaciones hechas, politicas de inspección, tratamiento de información y conﬁdencialidad.\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 text-right q-my-md" },
                [
                  _c("q-btn", {
                    staticClass: "q-px-lg q-mx-sm btn-app",
                    attrs: { color: "red", size: "md", label: "Atras" },
                    on: {
                      click: function($event) {
                        _vm.back(2)
                      }
                    }
                  }),
                  _c("q-btn", {
                    staticClass: "q-px-lg btn-app",
                    attrs: { color: "black", size: "md", label: "Guardar" },
                    on: { click: _vm.submitSave }
                  })
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.auth.userData.permissions["icda.inspections.all"]
        ? _c("div", [_c("inspectorComponent")], 1)
        : _c(
            "div",
            [
              _c(
                "q-page",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.data.load_inner,
                      expression: "!$store.state.data.load_inner"
                    }
                  ]
                },
                [
                  _c("div", { staticClass: "layout-padding q-py-lg" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "q-timeline",
                            {
                              staticClass: "border-bottom-none",
                              attrs: { color: "black", responsive: false }
                            },
                            [
                              _c(
                                "q-timeline-entry",
                                { attrs: { heading: "" } },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-6" }, [
                                      _c("span", [_vm._v("Lista de Ordenes ")])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c("q-select", {
                                          staticClass:
                                            "bg-white q-py-sm q-my-md",
                                          attrs: {
                                            placeholder: "Seleccionar Orden",
                                            options:
                                              _vm.optionsTypesInspectionsStatuesFiltered
                                          },
                                          model: {
                                            value:
                                              _vm.inspection_statues.status,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.inspection_statues,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inspection_statues.status"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("hr", { staticClass: "mt-0 q-my-sm" })
                                ]
                              ),
                              _vm._l(
                                _vm.$store.getters[
                                  "inspections/GET_INSPECTIONS"
                                ],
                                function(item, index) {
                                  return _c(
                                    "q-timeline-entry",
                                    {
                                      key: index,
                                      attrs: {
                                        heading: false,
                                        subtitle:
                                          item.created_at_date +
                                          " " +
                                          item.created_at_time +
                                          " ID-" +
                                          item.id +
                                          "",
                                        side: "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "border-bottom" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "link row",
                                              attrs: {
                                                to: {
                                                  name: "update.inspection",
                                                  params: {
                                                    inspection: item.id
                                                  }
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col mx-auto",
                                                  staticStyle: {
                                                    "max-width": "100px"
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm._f("img")(
                                                        item.gallery
                                                      ),
                                                      width: "100%"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col px-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tMatricula: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("uppercase")(
                                                          item.vehicle.board
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tTipo de Servicio: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("uppercase")(
                                                          item.vehicle
                                                            .service_type_text
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tTipo de Vehiculo: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("uppercase")(
                                                          item.vehicle
                                                            .type_vehicle_text
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tCliente : "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("uppercase")(
                                                          _vm.order.vehicle.user
                                                            .fullname
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tEstado: "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.inspection_status
                                                      )
                                                    )
                                                  ]),
                                                  _c("br")
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$store.getters[
                                  "inspections/GET_INSPECTIONS_LENGTH"
                                ] < 1,
                              expression:
                                "$store.getters['inspections/GET_INSPECTIONS_LENGTH'] < 1"
                            }
                          ],
                          staticClass: "col-12 q-px-md"
                        },
                        [
                          _c(
                            "q-alert",
                            {
                              staticClass: "q-mb-sm",
                              attrs: { color: "red", icon: "error", appear: "" }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tNO SE ENCONTRO ORDENES\n\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-12 d-block q-py-md" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "infinite-scroll",
                              rawName: "v-infinite-scroll",
                              value: _vm.loadMore,
                              expression: "loadMore"
                            }
                          ],
                          attrs: {
                            "infinite-scroll-disabled":
                              _vm.$store.getters["inspections/GET_PAGE_BUSY"],
                            "infinite-scroll-distance": "10"
                          }
                        })
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
      _c("notificationComponent", {
        on: {
          newInspection: function($event) {
            _vm.handlenewInspection()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-list",
    { staticClass: "q-pt-none", attrs: { id: "list_menu", separator: "" } },
    _vm._l(_vm.sidebar, function(lv1, key1) {
      return !lv1.children && lv1.can
        ? _c("singleItem", { key: key1, attrs: { item: lv1 } })
        : lv1.children && lv1.can
        ? _c(
            "dropdownItem",
            { attrs: { item: lv1 } },
            _vm._l(lv1.children, function(lv2, key2) {
              return !lv2.children && lv2.can
                ? _c("singleItem", { key: key2, attrs: { item: lv2 } })
                : lv2.children && lv2.can
                ? _c(
                    "dropdownItem",
                    { attrs: { item: lv2 } },
                    _vm._l(lv2.children, function(lv3, key3) {
                      return lv3.can
                        ? _c("singleItem", { key: key3, attrs: { item: lv3 } })
                        : _vm._e()
                    })
                  )
                : _vm._e()
            })
          )
        : _vm._e()
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
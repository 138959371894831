export const GET_INSPECTIONS = (state) => {
	return state.inspections
};

export const GET_INSPECTIONS_LENGTH = (state) => {
	return state.inspections.length
};

export const IF_EMPYT_INSPECTIONS = (state) => {
	return state.empyt
};

export const GET_PAGE = (state) => {
	return state.page
};

export const GET_PAGE_BUSY = (state) => {
	return state.busy
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row q-pt-lg" }, [
    _c(
      "div",
      { staticClass: "col-md-4 border-left" },
      [
        _c("ordersComponent", {
          attrs: {
            title: "En Espera",
            filter: {
              inspection_status: 0,
              order: { field: _vm.created_at, way: _vm.desc }
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-4 border-left" },
      [
        _c("ordersComponent", {
          attrs: {
            title: "Revisado",
            filter: {
              inspection_status: 1,
              order: { field: _vm.created_at, way: _vm.desc }
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-4 border-left" },
      [
        _c("ordersComponent", {
          attrs: {
            title: "Facturado",
            filter: {
              inspection_status: 2,
              order: { field: _vm.created_at, way: _vm.desc }
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
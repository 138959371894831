var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.type
      ? _c("div", { staticClass: "row q-mb-lg" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "col-8 col-md-9 col-lg-10" },
                [
                  _c(
                    "q-btn-group",
                    { staticClass: "bg-white" },
                    [
                      _c("q-btn", {
                        class: {
                          "bg-primary text-white": _vm.type_vehicle == 1
                        },
                        attrs: { label: "SENCILLO" },
                        on: {
                          click: function($event) {
                            _vm.type_vehicle = 1
                          }
                        }
                      }),
                      _c("q-btn", {
                        class: {
                          "bg-primary text-white": _vm.type_vehicle == 2
                        },
                        attrs: { label: "DOBLE LLANTA" },
                        on: {
                          click: function($event) {
                            _vm.type_vehicle = 2
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("q-checkbox", {
            attrs: { label: "Vigias" },
            model: {
              value: _vm.vigia,
              callback: function($$v) {
                _vm.vigia = $$v
              },
              expression: "vigia"
            }
          })
        ],
        1
      ),
      _vm.vigia
        ? _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c(
                "q-field",
                [
                  _c("q-input", {
                    staticClass: "bg-white q-py-sm q-my-sm",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.vigiaValue,
                      callback: function($$v) {
                        _vm.vigiaValue = $$v
                      },
                      expression: "vigiaValue"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "col-12 col-sm-8 col-md-9 col-lg-10" },
        [
          _vm._l(_vm.axes.$model, function(eje, item) {
            return _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "div",
                  { staticClass: "col", staticStyle: { "max-width": "200px" } },
                  [
                    _c("p", { staticClass: "font-weight-bold q-my-md" }, [
                      _vm._v("Eje  " + _vm._s(item + 1))
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "font-weight-bold q-mr-lg q-mt-md q-mb-lg"
                      },
                      [_vm._v("Presión Inicial:")]
                    ),
                    _c(
                      "p",
                      { staticClass: "font-weight-bold q-mr-lg q-my-md" },
                      [_vm._v("Ajuste:")]
                    )
                  ]
                ),
                _vm._l(eje, function(llanta, key) {
                  return _c(
                    "div",
                    {
                      staticClass: "col q-mx-sm",
                      staticStyle: { "max-width": "130px" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-center d-block q-my-sm" },
                        [
                          !_vm.type
                            ? _c("span", [
                                _vm._v(_vm._s(_vm._f("axes")(llanta.type)))
                              ])
                            : _c("span", [_vm._v(" ")])
                        ]
                      ),
                      _c(
                        "q-field",
                        {
                          attrs: {
                            error:
                              _vm.axes.$each[item].$each[key].pressure_init
                                .$error
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.vigia
                                      ? (llanta.pressure_init = _vm.vigiaValue)
                                      : ""
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("q-input", {
                            staticClass: "bg-white q-py-sm q-my-sm",
                            attrs: { type: "number" },
                            on: {
                              input: function($event) {
                                _vm.$emit("update-value-by-child", _vm.axes)
                              }
                            },
                            model: {
                              value: llanta.pressure_init,
                              callback: function($$v) {
                                _vm.$set(llanta, "pressure_init", $$v)
                              },
                              expression: "llanta.pressure_init"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "q-field",
                        {
                          attrs: {
                            error:
                              _vm.axes.$each[item].$each[key].adjustment.$error
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.vigia
                                      ? (llanta.adjustment = _vm.vigiaValue)
                                      : ""
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("q-input", {
                            staticClass: "bg-white q-py-sm q-my-sm",
                            attrs: { type: "number" },
                            on: {
                              input: function($event) {
                                _vm.$emit("update-value-by-child", _vm.axes)
                              }
                            },
                            model: {
                              value: llanta.adjustment,
                              callback: function($$v) {
                                _vm.$set(llanta, "adjustment", $$v)
                              },
                              expression: "llanta.adjustment"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _vm.axes.$model - 1 > item
                  ? _c("hr", { staticClass: "col-12 q-mb-lg" })
                  : _vm._e()
              ],
              2
            )
          }),
          !_vm.type
            ? _c(
                "div",
                {
                  staticClass: "q-mr-md d-inline-block",
                  on: { click: _vm.add }
                },
                [
                  _c(
                    "q-btn",
                    {
                      attrs: { round: "", color: "primary", size: "sm" },
                      on: {
                        click: function($event) {
                          _vm.addAxes = true
                        }
                      }
                    },
                    [_c("q-icon", { attrs: { name: "add" } })],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "q-ml-sm font-weight-bold cursor-pointer" },
                    [_vm._v(" Agregar Eje")]
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.type && _vm.axes.$model.length > 1
            ? _c(
                "div",
                {
                  staticClass: "q-mr-md d-inline-block",
                  on: { click: _vm.remove }
                },
                [
                  _c(
                    "q-btn",
                    {
                      attrs: { round: "", color: "red", size: "sm" },
                      on: {
                        click: function($event) {
                          _vm.addAxes = true
                        }
                      }
                    },
                    [_c("q-icon", { attrs: { name: "remove" } })],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "q-ml-sm font-weight-bold cursor-pointer" },
                    [_vm._v(" Remover Eje")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4 col-md-3 col-lg-2 q-mb-lg" }, [
      _c("span", { staticClass: "font-weight-bold" }, [_vm._v("Eje Trasero:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 col-sm-4 col-md-3 col-lg-2 q-mb-lg" },
      [_c("span", { staticClass: "font-weight-bold" }, [_vm._v("Llantas")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
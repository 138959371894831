var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixed-center text-center" },
    [
      _vm._m(0),
      _vm._m(1),
      _c(
        "q-btn",
        {
          staticStyle: { width: "200px" },
          attrs: { color: "secondary" },
          on: {
            click: function($event) {
              _vm.$router.push("/")
            }
          }
        },
        [_vm._v("Go back")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        staticStyle: { width: "30vw", "max-width": "150px" },
        attrs: { src: require("assets/sad.svg") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-faded" }, [
      _vm._v("Sorry, nothing here..."),
      _c("strong", [_vm._v("(404)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
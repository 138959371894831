export const GET_VEHICLES = (state) => {
	return state.vehicles
};

export const IF_EMPYT_VEHICLES = (state) => {
	return state.empyt
};

export const GET_PAGE = (state) => {
	return state.page
};

export const GET_PAGE_BUSY = (state) => {
	return state.busy
};
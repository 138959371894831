var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-padding" }, [
    _c(
      "div",
      { staticClass: "relative-position" },
      [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("q-table", {
              staticClass: "shadow-1 border-top-color",
              attrs: {
                data: _vm.table.data,
                columns: _vm.table.columns,
                pagination: _vm.table.pagination,
                filter: _vm.table.filter
              },
              on: {
                "update:pagination": function($event) {
                  _vm.$set(_vm.table, "pagination", $event)
                },
                request: _vm.getdata
              },
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function(props) {
                    return [
                      _c("div", { staticClass: "row full-width" }, [
                        _c("div", { staticClass: "col-12 col-md-6 q-mt-sm" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 col-md-6" },
                            [
                              _c(
                                "h1",
                                { staticClass: "q-headline text-dark" },
                                [
                                  _c("q-icon", {
                                    attrs: { name: "fas fa-car-side" }
                                  }),
                                  _vm._v(
                                    "\n                  Lista de Vehículos\n                "
                                  )
                                ],
                                1
                              ),
                              _c("q-search", {
                                staticClass: "text-dark w-100 uppercase",
                                attrs: {
                                  placeholder: "Buscar Por Placa",
                                  inverted: "",
                                  color: "white",
                                  "hide-underline": "",
                                  clearable: ""
                                },
                                on: {
                                  input: function($event) {
                                    _vm.getDataTable()
                                  }
                                },
                                model: {
                                  value: _vm.table.filter.board,
                                  callback: function($$v) {
                                    _vm.$set(_vm.table.filter, "board", $$v)
                                  },
                                  expression: "table.filter.board"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-6 q-mt-sm text-right" },
                          [
                            _c(
                              "q-btn",
                              {
                                staticClass: "q-ml-xs",
                                attrs: {
                                  to: { name: "vehicles.create" },
                                  icon: "add",
                                  color: "red",
                                  round: ""
                                }
                              },
                              [
                                _c("q-tooltip", { attrs: { delay: 300 } }, [
                                  _vm._v("Agregar Vehículo")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "q-btn",
                              {
                                staticClass: "q-ml-xs",
                                attrs: {
                                  icon: "fas fa-sync-alt",
                                  color: "info",
                                  rounded: "",
                                  flat: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.getDataTable()
                                  }
                                }
                              },
                              [
                                _c("q-tooltip", { attrs: { delay: 300 } }, [
                                  _vm._v("Refrescar Datos")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "body-cell-datos",
                  fn: function(props) {
                    return _c("q-td", { attrs: { props: props } }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "q-mr-md" },
                          [_c("q-icon", { attrs: { name: "fas fa-circle" } })],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  tag: "span",
                                  to: {
                                    name: "vehicles.update",
                                    params: { board: props.row.board }
                                  }
                                }
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "q-timeline-subtitle" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          props.row.created_at_date +
                                            " " +
                                            props.row.created_at_time
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _c("b", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(props.row.board) +
                                      " | " +
                                      _vm._s(props.row.model) +
                                      "\n                "
                                  )
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [_vm._v("Marca: ")]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        props.row.brand
                                          ? props.row.brand
                                          : "N/D"
                                      ) +
                                      "\n                "
                                  )
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [_vm._v("Tipo de Servicio: ")]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        props.row.service_type_text
                                          ? props.row.service_type_text
                                          : "N/D"
                                      ) +
                                      "\n                "
                                  )
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [_vm._v("Tipo de Vehículo: ")]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        props.row.type_vehicle_text
                                          ? props.row.type_vehicle_text
                                          : "N/D"
                                      ) +
                                      "\n                "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("inner-loading", { attrs: { visible: _vm.loading } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-page",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$store.state.data.load_inner,
          expression: "!$store.state.data.load_inner"
        }
      ]
    },
    [
      _c("div", { staticClass: "layout-padding q-py-lg" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-timeline",
                {
                  attrs: { responsive: "", color: "black", responsive: false }
                },
                [
                  _c("q-timeline-entry", { attrs: { heading: "" } }, [
                    _c("div", { staticClass: "row q-px-md" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("span", [_vm._v("Lista de Vehiculos")])
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-2 text-right" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "text-decoration-none q-btn inline relative-position q-btn-item non-selectable q-btn-rectangle q-focusable q-hoverable bg-red text-white",
                              attrs: { to: { name: "vehicles.create" } }
                            },
                            [_c("q-icon", { attrs: { name: "add" } })],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "row q-my-md q-mb-lg" },
                      [
                        _c(
                          "q-field",
                          {
                            staticClass: "col-12",
                            attrs: { error: _vm.$v.search.text.$error }
                          },
                          [
                            _c("q-input", {
                              staticClass: "w-100 uppercase",
                              attrs: {
                                placeholder: "BUSCAR POR PLACA",
                                after: [
                                  {
                                    icon: "search",
                                    handler: function handler() {
                                      _vm.searchPlaque()
                                    }
                                  }
                                ]
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !("button" in $event) &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchPlaque($event)
                                }
                              },
                              model: {
                                value: _vm.search.text,
                                callback: function($$v) {
                                  _vm.$set(_vm.search, "text", $$v)
                                },
                                expression: "search.text"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._l(_vm.$store.getters["vehicle/GET_VEHICLES"], function(
                    item,
                    index
                  ) {
                    return _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.search.show,
                            expression: "!search.show"
                          }
                        ],
                        key: index,
                        staticClass: "q-timeline-link",
                        attrs: {
                          to: {
                            name: "vehicles.update",
                            params: { board: _vm.uppercase(item.board) }
                          }
                        }
                      },
                      [
                        _c(
                          "q-timeline-entry",
                          {
                            attrs: {
                              heading: false,
                              title: _vm._f("title")(item),
                              subtitle:
                                item.created_at_date +
                                " " +
                                item.created_at_time,
                              side: "right"
                            }
                          },
                          [
                            _c("p", { staticClass: "mb-0" }, [
                              _c("b", [_vm._v("Marca: ")]),
                              _vm._v(_vm._s(item.brand ? item.brand : "N/D"))
                            ]),
                            _c("p", { staticClass: "mb-0" }, [
                              _c("b", [_vm._v("Tipo de Servicio: ")]),
                              _vm._v(
                                _vm._s(
                                  item.service_type_text
                                    ? item.service_type_text
                                    : "N/D"
                                )
                              )
                            ]),
                            _c("p", { staticClass: "mb-0" }, [
                              _c("b", [_vm._v("Tipo de Vehículo: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.type_vehicle_text
                                      ? item.type_vehicle_text
                                      : "N/D"
                                  )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("div", { staticClass: "col-12 d-block q-py-md" }, [
            _c("div", {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadMore,
                  expression: "loadMore"
                }
              ],
              attrs: {
                "infinite-scroll-disabled":
                  _vm.$store.getters["inspections/GET_PAGE_BUSY"],
                "infinite-scroll-distance": "10"
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
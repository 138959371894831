var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-padding" }, [
    _c(
      "div",
      { staticClass: "relative-position" },
      [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("q-table", {
              staticClass: "shadow-1 border-top-color",
              attrs: {
                "no-results-label": "No se encontraron Ordenes",
                "loading-label": "Cargando Datos",
                data: _vm.table.data,
                columns: _vm.table.columns,
                pagination: _vm.table.pagination,
                filter: _vm.table.filter
              },
              on: {
                "update:pagination": function($event) {
                  _vm.$set(_vm.table, "pagination", $event)
                },
                request: _vm.getdata
              },
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function(props) {
                    return [
                      _c("div", { staticClass: "row full-width" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "col-md-12 " }, [
                            _c("h1", { staticClass: "q-headline text-dark" }, [
                              _vm._v(
                                "\n                  Lista de Ordenes\n                "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "row gutter-x-md gutter-y-sm" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-xs-12 col-md-6" },
                                  [
                                    _c("q-select", {
                                      staticClass: "bg-white ",
                                      attrs: {
                                        placeholder: "Seleccionar Orden",
                                        options:
                                          _vm.optionsTypesInspectionsStatuesFiltered
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.getDataTable()
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.table.filter.inspection_status,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.table.filter,
                                            "inspection_status",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "table.filter.inspection_status"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-xs-12 col-md-6" },
                                  [
                                    _c("q-search", {
                                      staticClass: "text-dark w-100 uppercase",
                                      attrs: {
                                        placeholder: "Buscar Por Placa",
                                        inverted: "",
                                        debounce: 750,
                                        color: "white",
                                        "hide-underline": "",
                                        clearable: ""
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.getDataTable()
                                        }
                                      },
                                      model: {
                                        value: _vm.table.filter.board,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.table.filter,
                                            "board",
                                            $$v
                                          )
                                        },
                                        expression: "table.filter.board"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  }
                },
                {
                  key: "body-cell-datos",
                  fn: function(props) {
                    return _c("q-td", { attrs: { props: props } }, [
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "row gutter-x-md",
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  tag: "div",
                                  to: {
                                    name: "update.inspection",
                                    params: { inspection: props.row.id }
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-md-0 flex flex-center" },
                                  [
                                    _c("q-icon", {
                                      attrs: { name: "fas fa-circle" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-xs-6 col-md-2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img",
                                        style:
                                          "background-image: url(" +
                                          _vm.getUrlImage(props.row.gallery) +
                                          ");"
                                      },
                                      [
                                        _c(
                                          "q-chip",
                                          {
                                            staticClass: "text-white",
                                            staticStyle: {
                                              position: "absolute",
                                              bottom: "-1px",
                                              "border-radius":
                                                "0px 0px 5px 5px",
                                              width: "100%",
                                              "text-align": "center"
                                            },
                                            attrs: { color: "primary" }
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("uppercase")(
                                                    props.row.vehicle.board
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-xs-6 col-md-4 q-py-lg" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          props.row.created_at_date +
                                            " " +
                                            props.row.created_at_time
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  Tipo de Servicio: "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("uppercase")(
                                            props.row.vehicle.service_type_text
                                          )
                                        )
                                      )
                                    ]),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  Tipo de Vehiculo: "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("uppercase")(
                                            props.row.type_vehicle
                                          )
                                        )
                                      )
                                    ]),
                                    _c("br"),
                                    _vm._v("\n                  Cliente: "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("uppercase")(
                                            props.row.vehicle.user.fullname
                                          )
                                        )
                                      )
                                    ]),
                                    _c("br"),
                                    _vm._v("\n                  Estado: "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(props.row.inspection_status)
                                      )
                                    ]),
                                    _c("br")
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("inner-loading", { attrs: { visible: _vm.loading } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "lHh Lpr lFf" } },
    [
      _c(
        "q-layout-header",
        [
          _c(
            "q-toolbar",
            {
              attrs: {
                color: "primary",
                glossy: _vm.$q.theme === "mat",
                inverted: _vm.$q.theme === "ios"
              }
            },
            [
              _c(
                "q-btn",
                {
                  attrs: {
                    flat: "",
                    dense: "",
                    round: "",
                    "aria-label": "Menu"
                  },
                  on: {
                    click: function($event) {
                      _vm.leftDrawerOpen = !_vm.leftDrawerOpen
                    }
                  }
                },
                [_c("q-icon", { attrs: { name: "menu" } })],
                1
              ),
              _c("q-toolbar-title", [
                _vm._v("\n        Quasar App\n        "),
                _c("div", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
                  _vm._v("Running on Quasar v" + _vm._s(_vm.$q.version))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-layout-drawer",
        {
          attrs: {
            "content-class": _vm.$q.theme === "mat" ? "bg-grey-2" : null
          },
          model: {
            value: _vm.leftDrawerOpen,
            callback: function($$v) {
              _vm.leftDrawerOpen = $$v
            },
            expression: "leftDrawerOpen"
          }
        },
        [
          _c(
            "q-list",
            { attrs: { "no-border": "", link: "", "inset-delimiter": "" } },
            [
              _c("q-list-header", [_vm._v("Essential Links")]),
              _c(
                "q-item",
                {
                  nativeOn: {
                    click: function($event) {
                      _vm.openURL("http://quasar-framework.org")
                    }
                  }
                },
                [
                  _c("q-item-side", { attrs: { icon: "school" } }),
                  _c("q-item-main", {
                    attrs: { label: "Docs", sublabel: "quasar-framework.org" }
                  })
                ],
                1
              ),
              _c(
                "q-item",
                {
                  nativeOn: {
                    click: function($event) {
                      _vm.openURL("https://github.com/quasarframework/")
                    }
                  }
                },
                [
                  _c("q-item-side", { attrs: { icon: "code" } }),
                  _c("q-item-main", {
                    attrs: {
                      label: "GitHub",
                      sublabel: "github.com/quasarframework"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-item",
                {
                  nativeOn: {
                    click: function($event) {
                      _vm.openURL("https://discord.gg/5TDhbDg")
                    }
                  }
                },
                [
                  _c("q-item-side", { attrs: { icon: "chat" } }),
                  _c("q-item-main", {
                    attrs: {
                      label: "Discord Chat Channel",
                      sublabel: "https://discord.gg/5TDhbDg"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-item",
                {
                  nativeOn: {
                    click: function($event) {
                      _vm.openURL("http://forum.quasar-framework.org")
                    }
                  }
                },
                [
                  _c("q-item-side", { attrs: { icon: "record_voice_over" } }),
                  _c("q-item-main", {
                    attrs: {
                      label: "Forum",
                      sublabel: "forum.quasar-framework.org"
                    }
                  })
                ],
                1
              ),
              _c(
                "q-item",
                {
                  nativeOn: {
                    click: function($event) {
                      _vm.openURL("https://twitter.com/quasarframework")
                    }
                  }
                },
                [
                  _c("q-item-side", { attrs: { icon: "rss feed" } }),
                  _c("q-item-main", {
                    attrs: { label: "Twitter", sublabel: "@quasarframework" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("q-page-container", [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }